import { createRouter, createWebHistory } from "vue-router";
import Home from "@/views/Home";
import Login from "@/views/Login";
import FundInfo from "@/views/FundInfo";
import RiskSurvey from "@/views/surveys/RiskSurvey";
import KnowledgeRisk from "@/views/knowledge/InvestmentRisk";
import KnowledgeNAV from "@/views/knowledge/NAV";
import KnowledgeFund from "@/views/knowledge/Mutualfund";
import SimLayout from "@/components/layouts/SimLayout";
import Portfolio from "@/views/simDashboard/Portfolio";
import FundDiscovery from "@/views/simDashboard/FundDiscovery";
import OrderHistory from "@/views/simDashboard/OrderHistory";
import NotFound from "@/views/NotFound";
import { nextTick } from "vue";

const DEFAULT_TITLE = "investio";

const simulators = {
  path: "/simulator",
  name: "simulator",
  redirect: "/simulator/portfolio",
  component: SimLayout,
  children: [
    {
      path: "portfolio",
      name: "Portfolio",
      component: Portfolio,
      meta: { title: "Portfolio - investio", navBar: "PORTFOLIO" },
    },
    {
      path: "discovery",
      name: "FundDiscovery",
      component: FundDiscovery,
      meta: {
        title: "Fund Discovery - investio",
        navBar: "FUND DISCOVERY",
      },
    },
    {
      path: "history",
      name: "OrderHistory",
      component: OrderHistory,
      meta: {
        title: "Order History - investio",
        navBar: "ORDER HISTORY",
      },
    },
  ],
};

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/login",
    name: "Login",
    component: Login,
    props: route => ({ redirect: route.query.redirect }),
    meta: { title: "Login - Investio" },
  },
  {
    path: "/fund/:fundCode",
    name: "FundInfo",
    props: true, // route.params will be set as the component props.
    component: FundInfo,
  },
  {
    path: "/knowledge/NAV",
    name: "KnowledgeNAV",
    component: KnowledgeNAV,
  },
  {
    path: "/knowledge/investmentrisk",
    name: "KnowledgeRisk",
    component: KnowledgeRisk,
  },
  {
    path: "/knowledge/Mutualfund",
    name: "KnowledgeFund",
    component: KnowledgeFund,
  },
  {
    path: "/survey/risk",
    name: "RiskSurvey",
    component: RiskSurvey,
  },
  {
    path: "/survey/risk/result",
    name: "RiskResult",
    // route level code-splitting
    // this generates a separate chunk (RiskResult.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "RiskResult" */ "@/views/surveys/RiskResult"),
    // beforeEnter: (to, from, next) => {
    //   if (to.params.riskScore == null) {
    //     next({
    //       name: "RiskSurvey",
    //     });
    //   } else {
    //     next();
    //   }
    // },
  },
  simulators,
  {
    path: "/maintenance",
    name: "maintenance",
    component: () =>
      import(/* webpackChunkName: "Maintenance" */ "@/views/Maintenance"),

    // component: Maintenance,
    meta: { title: "Maintenance - Investio" },
  },
  {
    path: "/404",
    name: "NotFound",
    component: NotFound,
  },
  {
    path: "/:pathMatch(.*)*",
    // component: NotFound
    redirect: { name: "Home" },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  if (process.env.VUE_APP_MAINTAIN_MODE == "yes") {
    if (to.name == "maintenance") next();
    else next({ name: "maintenance" });
  } else {
    next();
  }
});

router.afterEach(to => {
  // Use next tick to handle router history correctly
  // see: https://github.com/vuejs/vue-router/issues/914#issuecomment-384477609
  nextTick(() => {
    document.title = to.meta.title || DEFAULT_TITLE;
  });
});

export default router;
