<template>
  <div class="form-control-static" style="padding-bottom:30px">
    <h3><b>Wallet Balance</b></h3>
    <div class="card col-md-12">
      <form class="form-horizontal">
        <div class="card-content">
          <div class="clearfix">
            <h4>
              Avaliable: &#3647;
              {{ Number(walletAvaliableBalance).toLocaleString() }}
            </h4>

            <!-- <el-button class="topup-btn" disabled>Topup</el-button> -->
          </div>

          <!-- <el-divider /> -->

          <!-- <div class="col-md-12"> -->
          <p>
            Total spent money : &nbsp; &#3647;
            {{ Number(walletTotalSpend).toLocaleString() }}
          </p>
          <!-- <p>
              In asset &nbsp;&nbsp;&nbsp;&nbsp;: &nbsp; &#3647;
              {{ Number(walletInAssetBalance).toLocaleString() }}
            </p> -->
          <!-- <p>
              Total &nbsp;: &nbsp; &#3647;
              {{ Number(totalWalletBalance).toLocaleString() }}
            </p> -->
          <!-- <br />
          </div> -->
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import useFundWallet from "@/composables/fundWallet";
export default {
  setup() {
    const { walletAvaliableBalance, walletTotalSpend } = useFundWallet();

    return {
      walletAvaliableBalance,
      walletTotalSpend,
    };
  },
};
</script>
