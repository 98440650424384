import { xxHash32 } from "js-xxhash";
import { computed, ref } from "vue";
import {
  getAllCategories,
  getAllAmcs,
  getTopReturn,
} from "@/resources/fund.api";
import { useToast } from "vue-toastification";
import { openDB } from "idb";

const categories = ref([]);
const amcs = ref([]);
const catCascade = ref([]);
const topReturn = ref([]);
const briefFundCollection = ref([]);
const selectedSearchFund = ref();

export default function useFundInfo() {
  const toast = useToast();

  async function UpdateFundCats() {
    if (categories.value.length != 0) return;

    const db = await openDB("fund");
    let catData = await db.getAll("brdCategories");

    if (catData.length != 0) {
      // check if data is older than 1 day -> update
      const diffMs = Date.now() - catData[0].last_updated;
      if (Math.floor(diffMs / 1000 / 60 / 60) < 24) {
        setCategories(catData);
        return;
      }

      // Remove expired data
      db.delete("brdCategories");
      db.delete("categories");
    }
    try {
      const response = await getAllCategories();
      catData = response.data;
    } catch (err) {
      toast.warning("Fetching failed: category");
      throw err;
    }

    const dbTransaction = db.transaction(
      ["brdCategories", "categories"],
      "readwrite"
    );

    catData.forEach(element => {
      element.last_updated = Date.now();
      dbTransaction.objectStore("brdCategories").add(element);
      element.cats.forEach(c => {
        c.last_updated = Date.now();
        dbTransaction.objectStore("categories").add(c);
      });
    });

    setCategories(catData);

    dbTransaction.done.catch(err => {
      toast.error("IDB Write Failed: category");
      console.error(err);
    });
  }

  async function getCategoryByID(id) {
    const db1 = await openDB("fund");
    let transaction = db1.transaction("brdCategories", "readonly");
    const bcat = await transaction.store.get(id);
    return bcat;
  }

  async function getAllCat() {
    const db1 = await openDB("fund");
    let transaction = db1.transaction("brdCategories", "readonly");
    const bcat = await transaction.store.getAll();
    return bcat;
  }

  function setCategories(categoryList) {
    categories.value = categoryList;
    catCascade.value = categoryList.map(transformCat);
  }

  const transformCat = brdCat => {
    const subCat = brdCat["cats"];

    if (subCat.length > 1) {
      const cats = subCat.map(c => {
        return {
          value: c["cid"],
          label: c["name_en"],
        };
      });

      return {
        value: brdCat["bid"],
        label: brdCat["name_en"],
        children: cats,
      };
    }

    return {
      value: subCat[0]["cid"],
      label: subCat[0]["name_en"],
    };
  };

  const FetchAmcs = async () => {
    if (amcs.value.length == 0) {
      const response = await getAllAmcs(); // fetch amcs
      setAmcs(response.data);
    }
  };

  function setAmcs(amcList) {
    amcs.value = amcList;
  }

  const FetchTopReturn = async (cat, range, amc, risk) => {
    // Set Is loading
    try {
      const response = await getTopReturn(
        cat,
        range.value,
        amc.value,
        risk.value
      );
      const data = response.data;
      if (data == null) {
        topReturn.value = null;
        return;
      }
      let i = 0;
      let top = data.map(fund => ({
        ...fund,
        count: ++i,
        peer_comp_1y: Number.parseFloat(
          fund.total_return_1y - fund.total_return_avg_1y
        ).toFixed(4),
      }));
      topReturn.value = top;
    } catch (err) {
      toast.warning("Fetch data failed: Top Return");
      console.error("Cannot fetch data: Top Return ", err);
    }
  };

  // Result from search
  function addBriefFunds(searchedList) {
    console.debug("Brief search: ", searchedList);
    briefFundCollection.value = searchedList;
  }

  function setSelectedFund(fund) {
    selectedSearchFund.value = fund;
  }

  const zeroPad = (num, places) => String(num).padStart(places, "0");

  function getFundID(code) {
    let hashID = xxHash32(code.toUpperCase(), 32349).toString(16);
    return zeroPad(hashID, 8);
  }

  return {
    UpdateFundCats,
    FetchAmcs,
    FetchTopReturn,
    addBriefFunds,
    getFundID,
    setSelectedFund,
    getCategoryByID,
    getAllCat,

    fundAmcs: computed(() => amcs.value),
    catCascader: computed(() => catCascade.value),
    fundCats: computed(() => categories.value),
    selectedFund: computed(() => selectedSearchFund.value),
    topReturn,
  };
}
