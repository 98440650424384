<template>
  <el-result
    icon="success"
    title="Purchase successfully"
    subTitle="Purchase order has been submitted."
  >
    <template #extra>
      <!-- <el-button>Back</el-button> -->
      <el-button @click="goToFundInfoPage">View Fund Info</el-button>
      <el-button type="primary" @click="goToPortfolio"
        >View Portfolio</el-button
      >
    </template>
  </el-result>
</template>

<script>
import { useRouter } from "vue-router";
import { useFundTransactionBuy } from "@/composables/fundTransaction";
export default {
  setup() {
    const router = useRouter();
    const { closeBuyModal } = useFundTransactionBuy();

    const goToPortfolio = () => {
      closeBuyModal();
      router.push({ name: "Portfolio" });
    };

    const goToFundInfoPage = () => {
      closeBuyModal();
      router.push({ name: "FundInfo" });
    };

    return {
      goToPortfolio,
      goToFundInfoPage,
    };
  },
};
</script>
