<template>
  <footer class="footer">
    <div class="container-fluid">
      <div class="copyright pull-right">
        COPYRIGHT &copy; 2021 MUTUAL FUND SIMULATOR
      </div>
    </div>
  </footer>
</template>
<script>
export default {};
</script>
<style></style>
