<template>
  <base-layout>
    <template v-slot:content>
      <div class="wrapper wrapper-full-page">
        <div class="full-page full-page-no-filter login-page">
          <div class="content">
            <div class="container">
              <div class="row">
                <div class="col-md-6 col-sm-6 col-md-offset-6 col-sm-offset-3">
                  <div class="card-header">
                    <h2 class="topic topic-header">
                      Welcome
                    </h2>
                    <h3 style="color:#ffffff ; padding-bottom: 20px;">
                      Thai Mutual Funds Simulator Platform
                    </h3>
                    <fund-search-box />
                  </div>
                  <div class="card-footer"></div>
                </div>
              </div>
            </div>
          </div>

          <div
            class="full-page-background"
            :style="{
              backgroundImage:
                'url(' + `${publicPath}img/bg/fin-head-p.jpg` + ')',
            }"
          ></div>
        </div>
      </div>

      <div class="register-page">
        <div class="content">
          <div class="container">
            <div class="row">
              <div class="col-md-12" style="margin:100px 0px 50px 0px">
                <fund-top-return />
              </div>
            </div>
          </div>
        </div>
      </div>

      <!------------------------------ risk questionair question --------------------------------------------------------------------->

      <div class="register-page">
        <div class="content">
          <div class="container">
            <div class="row" style="padding-bottom:60px">
              <h2 class="center topic">Guideline</h2>
              <div
                class="guide col-md-6"
                style="min-height: 200px; margin-top: 7%; "
              >
                <router-link :to="{ name: 'RiskSurvey' }">
                  <div
                    class="col-md-6 "
                    style="margin-top: 30px; color:#252422"
                  >
                    <div class="col-md-12 center zoom">
                      <img src="@/assets/static/img/icon-1.png" />
                      <h4 class="zero-top">Risk questionnaire</h4>
                    </div>
                  </div>
                </router-link>

                <router-link :to="{ name: 'KnowledgeFund' }">
                  <div
                    class="col-md-6"
                    style="margin-top: 30px;border-left: 2px solid #ececec; color:#252422"
                  >
                    <div class="col-md-12 center zoom">
                      <img src="@/assets/static/img/icon-2.png" />
                      <h4 class="zero-top center">Fund basic knowledge</h4>
                    </div>
                  </div>
                </router-link>
              </div>

              <div class="col-md-5 col-sm-offset-1">
                <img
                  src="@/assets/static/img/guideline-pic-1.jpg"
                  alt="Guideline"
                  style="max-width:100%;padding-top: 30px;"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
  </base-layout>
</template>

<script>
import BaseLayout from "@/components/layouts/BaseLayout";
import FundSearchBox from "@/components/shared/FundSearchBox";
import FundTopReturn from "@/components/pages/home/FundTopReturn";
export default {
  name: "Home",
  components: {
    BaseLayout,
    FundSearchBox,
    FundTopReturn,
  },
  setup() {
    return {
      publicPath: process.env.BASE_URL,
    };
  },
};
</script>

<style scoped>
.wrapper.wrapper-full-page {
  height: 75%;
  min-height: 75vh;
}

.full-page > .content {
  min-height: 35vh;
}

.center {
  text-align: center;
}

.topic-header {
  color: #ffffff;
  font-weight: bold;
}
.wrapper.wrapper-full-page {
  height: 75%;
  min-height: 75vh;
}

.full-page > .content {
  min-height: 35vh;
}

.zoom {
  transition: all 0.3s;
}

.zoom:hover {
  transform: scale(1.1);
  transition: all 0.4s;
}
</style>
