import axios from "axios";

const httpClient = axios.create({
  baseURL: "https://investio-api.dewkul.me", //process.env.VUE_APP_API_URL,
  timeout: 10000,
  // https://stackoverflow.com/questions/32500073/request-header-field-access-control-allow-headers-is-not-allowed-by-itself-in-pr
  // headers: {
  //   "Content-Type": "application/json",
  //   "Access-Control-Allow-Origin": "*",
  // "Access-Control-Allow-Methods": "GET,HEAD,OPTIONS,POST,PUT"),
  // "Access-Control-Allow-Headers": "Origin, X-Requested-With, Content-Type, Accept, Authorization"
  //
  // anything you want to add to the headers
  // }
});

const httpClientWithAuth = axios.create({
  baseURL: "https://investio-api.dewkul.me",
  timeout: 10000,
});

httpClientWithAuth.defaults.withCredentials = true;

export { httpClient, httpClientWithAuth };
