import { ref, reactive } from "vue";
import { openDB } from "idb";
import { httpClientWithAuth } from "@/resources/httpClients";

// interface User {
//      name: string;
//      id: string;
// }

const aToken = ref("");
const tokenExp = ref(0);
const isAuth = ref(false);
const authError = ref();

const authState = reactive({
  isAuth,
  authError,
  aToken,
  tokenExp,
});

export default function useAuth() {
  const setAuth = async auth => {
    console.debug("Auth: ", auth);
    httpClientWithAuth.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${auth.acc}`;
    const userDB = await openDB("userPref");
    try {
      userDB.put("ud", {
        key: "tk",
        val: auth.ref,
      });
    } catch (err) {
      console.error("IDB Auth err: ", err);
    }
    userDB.close();
    aToken.value = auth.acc;

    tokenExp.value = auth.a_exp;
    authState.isAuth = true;
  };

  const clearAuth = async () => {
    // rm from db
    const userDB = await openDB("userPref");
    try {
      await userDB.put("ud", {
        key: "tk",
        val: "",
      });
    } catch (err) {
      console.error("IDB Auth clear err ", err);
    }

    aToken.value = "";
    authState.isAuth = false;
    authState.authError = undefined;
  };

  const setAuthFail = err => {
    authState.authError = err;
  };

  const getToken = async () => {
    const userDB = await openDB("userPref");
    const userTDB = userDB.transaction("ud", "readonly");
    try {
      const tkData = await userTDB.store.get("tk");
      return await tkData.val;
    } catch (err) {
      console.debug("Error while read token db: ", err);
      throw err;
    }
  };

  return {
    setAuth,
    clearAuth,
    setAuthFail,
    getToken,
    authState,
    isAuth,
  };
}
