<template>
  <div class="user">
    <div class="photo">
      <img src="@/assets/profile.jpg" />
    </div>
    <div class="info">
      <a
        data-toggle="collapse"
        @click="toggleMenu"
        href="javascript:void(0)"
        style="font-size:15px;"
      >
        <span v-if="username">
          {{ username }}
        </span>
        <span v-else>
          Please Log in
        </span>
      </a>
    </div>
  </div>
</template>
<script>
// import CollapseTransition from "element-plus/lib/transitions/collapse-transition";
import { ElCollapseTransition } from "element-plus";
import useUserData from "@/composables/userData";
import { toRefs } from "vue";
export default {
  components: {
    [ElCollapseTransition.name]: ElCollapseTransition,
  },
  data() {
    return {
      isClosed: true,
    };
  },
  methods: {
    toggleMenu() {
      this.isClosed = !this.isClosed;
    },
  },
  setup() {
    const { userState } = useUserData();
    const { username } = toRefs(userState);

    return {
      username,
    };
  },
};
</script>
