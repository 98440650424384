<template>
  <el-autocomplete
    placeholder="Search by fund name or fund code"
    style="min-width:410px"
    v-model="fundInput"
    :trigger-on-focus="false"
    prefix-icon="el-icon-search"
    @select="handleSelectedFund"
    :fetch-suggestions="querySearchSuggestion"
    clearable
  >
  </el-autocomplete>
</template>

<style scoped>
.el-input {
  width: 20em;
}
</style>
<script>
import { ref } from "vue";
import useFundInfo from "@/composables/fundInfo";
import { useRouter } from "vue-router";
import { getSearchFund } from "@/resources/fund.api";

export default {
  name: "FundSearchBox",
  setup() {
    const fundInput = ref("");
    const engRegEx = new RegExp("^[a-zA-Z]+$");

    const { addBriefFunds, setSelectedFund } = useFundInfo();
    const router = useRouter();

    const handleSelectedFund = fund => {
      setSelectedFund(fund);
      router.push({ name: "FundInfo", params: { fundCode: fund.code } });
    };

    async function querySearchSuggestion(queryStr, cb) {
      let isEng = false;
      if (engRegEx.test(queryStr)) {
        isEng = true;
      }
      getSearchFund(queryStr)
        .then(response => {
          addBriefFunds(response.data);
          const suggests = response.data.map(f => ({
            value: isEng
              ? `${f.code} - ${f.name_en}`
              : `${f.code} - ${f.name_th}`,
            code: f.code,
          }));
          cb(suggests);
        })
        .catch(err => {
          console.error("search: ", err);
        });
    }

    return {
      fundInput,
      handleSelectedFund,
      querySearchSuggestion,
    };
  },
};
</script>
