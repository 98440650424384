<template>
  <div>
    <div class="col-md-8 col-md-offset-2">
      <port-wallet></port-wallet>
    </div>

    <div class="col-md-8 col-md-offset-2">
      <buy-fund-modal></buy-fund-modal>
      <sell-fund-modal></sell-fund-modal>

      <!-- <port-wallet></port-wallet> -->
      <!-- <div class="col-sm-5 col-md-offset-0"> -->
      <!-- {{ myPorts }} -->
      <h3><b>Acceptable Risk Level</b></h3>
      <h5>Level {{ maxRiskAcceptanceLevel }} - {{ investorType }}</h5>

      <h3><b>My Portfolio</b></h3>
      <port-pie-chart></port-pie-chart>
      <div v-show="isPortEmpty">
        <el-empty description="Portfolio is empty" :image-size="200"></el-empty>
      </div>

      <div v-for="f in myPort.funds" :key="f.fund_id">
        <port-fund-card :fund="f"></port-fund-card>
      </div>
    </div>
  </div>
</template>
<script>
import useFundPort from "@/composables/fundPort";
import { computed, toRefs } from "vue";
import PortFundCard from "@/components/pages/simDashboard/PortFundCard";
import PortWallet from "@/components/pages/simDashboard/PortWallet";
import PortPieChart from "@/components/pages/simDashboard/PortPieChart";
import BuyFundModal from "@/views/modals/BuyFundModal";
import SellFundModal from "@/views/modals/SellFundModal";
import useUserData from "@/composables/userData";

export default {
  components: {
    PortFundCard,
    PortWallet,
    BuyFundModal,
    SellFundModal,
    PortPieChart,
  },
  setup() {
    const { myPort } = useFundPort();
    const { maxRiskAcceptanceLevel, investorType } = useUserData();

    const { funds } = toRefs(myPort);

    return {
      myPort,
      isPortEmpty: computed(() => {
        if (funds.value.length == 0) return true;
        return false;
      }),
      maxRiskAcceptanceLevel,
      investorType,
    };
  },
};
</script>

<style>
.main-panel > .content {
  padding: 300px 15px 0 15px;
  min-height: calc(100% - 123px);
}
</style>
