<template>
  <div>
    <el-affix>
      <nav
        class="navbar navbar-transparent navbar-absolute"
        :class="isNavDark ? 'nav-dark' : 'nav-light'"
      >
        <div class="container">
          <div class="navbar-header">
            <button
              type="button"
              class="navbar-toggle"
              data-toggle="collapse"
              data-target="#navigation-example-2"
              @click="toggleNavbar"
            >
              <span class="sr-only">Toggle navigation</span>
              <span class="icon-bar"></span>
              <span class="icon-bar"></span>
              <span class="icon-bar"></span>
            </button>
            <router-link class="navbar-brand" :to="{ name: 'Home' }">
              <span style="-webkit-text-stroke: 0.2px black;"
                >INVESTIO&nbsp;</span
              >
              <el-tag type="success" size="mini">BETA</el-tag>
            </router-link>
          </div>
          <div class="collapse navbar-collapse">
            <!-- <el-avatar
            class="nav navbar-nav navbar-right"
            icon="el-icon-user-solid"
          ></el-avatar> -->
            <span class="nav navbar-nav navbar-right">
              <!-- <i class="el-icon-arrow-down el-icon--right"></i> -->
            </span>
            <ul v-if="isAuth" class="nav navbar-nav navbar-right">
              <!-- <slot name="nav-link"></slot> -->
              <!-- <li>
                <router-link :to="{ name: 'Portfolio' }">
                  Portfolio
                </router-link>
              </li> -->
              <!-- <li>
              Logout
            </li> -->
              <avatar-menu></avatar-menu>
            </ul>

            <ul v-else class="nav navbar-nav navbar-right">
              <li v-if="isAuthPage">
                <router-link :to="{ name: 'Home' }">
                  Home
                </router-link>
              </li>
              <li v-else>
                <el-button
                  type="primary"
                  @click="pushToLogIn"
                  style="margin-top:20px"
                >
                  Log In
                </el-button>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </el-affix>

    <slot name="content"></slot>

    <div class="collapse navbar-collapse off-canvas-sidebar">
      <ul class="nav nav-mobile-menu">
        <ul v-if="isAuth" class="nav">
          <!-- <slot name="nav-link"></slot> -->
          <li>
            <router-link :to="{ name: 'Portfolio' }">
              Portfolio
            </router-link>
          </li>
          <!-- <li>
              Logout
            </li> -->
        </ul>
        <ul v-else class="nav">
          <li v-if="isAuthPage">
            <router-link :to="{ name: 'Home' }">
              Home
            </router-link>
          </li>
          <li v-else>
            <a @click="pushToLogIn">
              Log In
            </a>
          </li>
        </ul>
      </ul>
    </div>

    <div class="footer"></div>
  </div>
</template>

<script>
import { onBeforeUnmount, toRefs } from "vue";
import AvatarMenu from "@/components/shared/avatarMenu";
import { useRouter } from "vue-router";
import useAuth from "@/composables/auth";
export default {
  name: "BaseLayout",
  props: {
    isNavDark: {
      type: Boolean,
      default: false,
    },
    isAuthPage: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    AvatarMenu,
  },
  setup(props) {
    const { authState } = useAuth();
    const { isAuth } = toRefs(authState);

    const router = useRouter();

    function toggleNavbar() {
      document.body.classList.toggle("nav-open");
    }
    function closeMenu() {
      document.body.classList.remove("nav-open");
      document.body.classList.remove("off-canvas-sidebar");
    }

    onBeforeUnmount(() => closeMenu());

    function pushToLogIn() {
      if (props.isNavDark) router.push({ name: "Login" });
      else {
        router.push({ name: "Login", query: { redirect: "Portfolio" } });
      }
    }

    return {
      isAuth,
      toggleNavbar,
      closeMenu,
      pushToLogIn,
    };
  },
};
</script>

<style scoped>
.nav-dark {
  background: #323232;
}

.nav-light {
  background: "";
}

.footer {
  background-color: #323232;
  width: 100%;
  height: 75px;
  margin-top: 30px;
}
</style>
