import { reactive, ref, computed } from "vue";
import { postRiskScore, getUserData } from "@/resources/userdata.api";

const username = ref("");
const uid = ref(0);
const riskScore = ref(0);
const riskUpdateAt = ref("");

const userState = reactive({
  username,
  uid,
  riskScore,
  riskUpdateAt,
});

const riskTolerance = reactive({
  riskScore,
  riskUpdateAt,
});

export default function useUserData() {
  function SetUserData(data) {
    userState.username = data.username;
    userState.uid = data.uid;
  }

  async function SetRiskScore(score) {
    await postRiskScore(score);
    userState.riskScore = score;
    userState.riskUpdateAt = Date.now();
  }

  const maxRiskAcceptanceLevel = computed(() => {
    if (riskScore.value < 15) return 1;
    if (riskScore.value < 22) return 4;
    if (riskScore.value < 30) return 5;
    if (riskScore.value < 37) return 7;
    return 8;
  });

  const investorType = computed(() => {
    if (maxRiskAcceptanceLevel.value < 2) return "Low risk";
    if (maxRiskAcceptanceLevel.value < 5) return "Low to moderate risk";
    if (maxRiskAcceptanceLevel.value < 6) return "Moderate risk";
    if (maxRiskAcceptanceLevel.value < 8)
      return "Moderate to moderately high risk";
    return "High risk";
  });

  const ClearUserData = () => {
    username.value = "";
    uid.value = 0;
    riskScore.value = 0;
    riskUpdateAt.value = 0;
  };

  const FetchUserData = async () => {
    try {
      const response = await getUserData();

      riskScore.value = response.data.risk_score;
      riskUpdateAt.value = response.data.risk_last_updated;
      console.debug("UD: ", response.data);
    } catch (err) {
      console.error("UD: ", err);
    }
  };

  return {
    SetUserData,
    SetRiskScore,
    userState,
    riskTolerance,
    maxRiskAcceptanceLevel,
    username,
    ClearUserData,
    FetchUserData,
    investorType,
  };
}
