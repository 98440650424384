import { getNavSeries } from "@/resources/fund.api";
import { ref, computed } from "vue";
import Decimal from "decimal.js-light";
import { openDB } from "idb";
import { useToast } from "vue-toastification";
import dayjs from "dayjs";

const navFund = ref([]); // Use in NavChart
const lastNAV = ref(null);
const latestNavData = ref(); // Use in Buy/Sell TODO: use reactive
const currentFundID = ref("");

function useFundNAV() {
  const toast = useToast();

  const FetchNavSeries = async (fID, navRange) => {
    console.debug(`Fetch NAV of ${fID} - ${navRange}`);

    try {
      const response = await getNavSeries(fID, navRange);
      navFund.value = response.data;

      // FundID Change
      if (currentFundID.value != fID) {
        console.debug("Set last NAV ", navFund.value);
        lastNAV.value =
          navFund.value.length > 0
            ? new Decimal(navFund.value[navFund.value.length - 1][1])
            : null;

        // TODO: write latest nav in IDB
        // latest_nav, nav_change_1d, fund_id,
        await writeLatestNAV(
          fID,
          navFund.value[navFund.value.length - 1][1],
          navFund.value[navFund.value.length - 2][1],
          navFund.value[navFund.value.length - 1][0]
        );
        currentFundID.value = fID;
      }
    } catch (err) {
      console.warn("ERR fetch navs ", err);
      toast.warning("Fetching data failed: NAV Series");
    }
  };

  async function writeLatestNAV(fID, lastNav, prevNav, dataTs) {
    const fundDb = await openDB("fund");
    const dbTransaction = fundDb.transaction("latestNAV", "readwrite");
    let lastNavData = await dbTransaction.store.get(fID);
    latestNavData.value = lastNavData;

    if (lastNavData == null) {
      // if (latestNavData.value == null) {
      lastNavData = {
        fund_id: fID,
        current_nav: lastNav,
        is_change_up: lastNav - prevNav > 0,
        data_date: dataTs,
        last_updated: Date.now(),
      };
      dbTransaction.store.add(lastNavData); // Cannot add ref directly -> could not be cloned
      latestNavData.value = lastNavData;
      console.debug("Add latestNavData");
    } else {
      console.debug("OLD ja");
      // Update existing one

      if (dayjs().diff(latestNavData.value.last_updated, "hour") > 8) {
        console.debug(
          "Update IDB LatestNav: ",
          latestNavData.value.last_updated
        );
        lastNavData = {
          fund_id: fID,
          current_nav: lastNav,
          is_change_up: lastNav - prevNav > 0,
          data_date: dataTs,
          last_updated: Date.now(),
        };
        dbTransaction.store.put(lastNavData); // Cannot add ref directly -> could not be cloned
        latestNavData.value = lastNavData;
      }
    }

    dbTransaction.done
      .then(() => {
        console.debug("NavData ", latestNavData.value);
      })
      .catch(err => {
        console.error("IDB Failed: NAV Latest ", err);
        toast.error("IDB Failed: NAV Latest");
      });
  }

  return {
    navFund: computed(() => navFund.value),
    navChange: computed(() => {
      if (navFund.value.length > 0) {
        const firstNAV = navFund.value[0][1];
        const result = lastNAV.value.minus(firstNAV);
        return result.isPositive()
          ? `+${result.toString()}`
          : result.toString();
      }
      return null;
    }),
    navChangePercent: computed(() => {
      console.debug("navChangeLOg: ", navFund.value);
      if (lastNAV.value != null && navFund.value.length > 0) {
        const firstNAV = navFund.value[0][1];
        return lastNAV.value
          .minus(firstNAV)
          .div(firstNAV)
          .mul(100)
          .todp(3)
          .toString();
      }
      return null;
    }),
    FetchNavSeries,
    latestNavData,
    currentNav: computed(() => latestNavData.value["current_nav"]),
    currentNavDate: computed(() => dayjs(latestNavData.value.data_date)),
  };
}

function useFundNavScope() {
  const latestNavDataScope = ref();
  const navFundScopeList = ref([]);
  const toast = useToast();

  const FetchNavLatestScope = async fID => {
    // Read from idb -> if not found -> fetch latest nav -> add to idb
    const fundDb = await openDB("fund");
    const data = await fundDb.get("latestNAV", fID);
    console.debug("data ", data);

    if (data != null) {
      if (dayjs().diff(data.last_updated, "hour") < 8) {
        latestNavDataScope.value = data;
        return;
      }
    }

    try {
      const response = await getNavSeries(fID, "2w"); // TODO: 2w
      console.debug("Fetch latest NAV SCOPE ", response.data);
      navFundScopeList.value = response.data;

      // TODO: write latest nav in IDB
      // latest_nav, nav_change_1d, fund_id,
      await writeLatestScopeNAV(
        fID,
        navFundScopeList.value[navFundScopeList.value.length - 1][1],
        navFundScopeList.value[navFundScopeList.value.length - 2][1],
        navFundScopeList.value[navFundScopeList.value.length - 1][0]
      );
    } catch (err) {
      console.warn("ERR fetch navs ", err);
      toast.warning("Fetching data failed: NAV Scope ", fID);
    }
  };

  async function writeLatestScopeNAV(fID, lastNav, prevNav, dataTs) {
    const fundDb = await openDB("fund");
    const dbTransaction = fundDb.transaction("latestNAV", "readwrite");

    // Check new or update
    let data = await dbTransaction.store.get(fID);
    console.debug("Scope nav ", data);

    if (data == null) {
      data = {
        fund_id: fID,
        current_nav: lastNav,
        is_change_up: lastNav - prevNav > 0,
        data_date: dataTs,
        last_updated: Date.now(),
      };
      dbTransaction.store.add(data); // cannot add ref -> could not be cloned
      latestNavDataScope.value = data;
      console.debug("Add latestNavDataScope");
    } else {
      console.debug("OLD ja");
      // Update existing one

      // if (dayjs(latestNavDataScope.value.last_updated).date() != dayjs().date()) {
      // if (dayjs().diff(latestNavData.value.last_updated, "hour") > 8) {
      console.debug("Update IDB LatestNavScope: ", data.last_updated);
      data = {
        fund_id: fID,
        current_nav: lastNav,
        is_change_up: lastNav - prevNav > 0,
        data_date: dataTs,
        last_updated: Date.now(),
      };
      dbTransaction.store.put(data);
      latestNavDataScope.value = data;
    }

    dbTransaction.done
      .then(() => {
        // navFund.value.data = lastNavData;
        console.debug("NavData IDB", latestNavDataScope.value);
      })
      .catch(err => {
        console.error("IDB Failed: NAV Latest Scope ", fID, err);
        toast.error("IDB Failed: NAV Latest Scope ", fID);
      });
  }

  function SetGlobal() {
    navFund.value = navFundScopeList.value;
    latestNavData.value = latestNavDataScope.value;
    lastNAV.value = new Decimal(latestNavDataScope.value.current_nav);
  }

  return {
    FetchNavLatestScope,
    latestNavDataScope,
    SetGlobal,
  };
}

export { useFundNAV, useFundNavScope };
