export default [
  {
    name: "Portfolio",
    icon: "ti-wallet",
    path: "portfolio",
  },
  {
    name: "Fund Discovery",
    icon: "ti-layout",
    path: "discovery",
  },
  {
    name: "Order History",
    icon: "ti-list",
    path: "history",
  },
];
