<template>
  <base-layout isNavDark>
    <template v-slot:content>
      <div class="wrapper wrapper-full-page">
        <div class="register-page">
          <div class="content">
            <div class="container">
              <div class="row">
                <div class="col-md-8 col-md-offset-2">
                  <div class="header-text">
                    <h2 style="padding-top: 60px">
                      Risk Tolerance Questionnaire
                    </h2>
                    <h4>
                      Better understanding among investors with regards to risk
                      tolerance
                    </h4>
                    <!-- {{ selectedAns }} -->
                  </div>
                </div>

                <div class="col-md-4 col-md-offset-2">
                  <div class="img-container">
                    <img
                      src="@/assets/static/img/riskSurveyHeader.png"
                      alt="Agenda"
                      style="max-width:100%"
                    />
                  </div>
                </div>
                <div class="col-md-5">
                  <h5 style="line-height: 2.0;">
                    The questionnaire is provided only as preliminary investment
                    guidance in accordance with a given risk tolerance score.
                    Investors should consider other factors to establish a
                    well-balanced investment strategy, e.g. investment
                    objectives, time horizon, etc. Investors may seek practical
                    advice from professional consultants.
                  </h5>
                </div>

                <div class="col-md-9 col-md-offset-2">
                  <br />
                  <h5>
                    Please choose only <b>ONE</b> answer that best describes
                    you.
                  </h5>
                  <div v-for="(item, itemIndex) in questions" :key="item.num">
                    <h5 style="line-height: 1.7;">
                      {{ item.num }}. {{ item.q }}
                    </h5>
                    <div v-if="item.img" class="col-md-5 tab-left ">
                      <img
                        :src="require('@/assets/static/img/' + item.img)"
                        alt="Agenda"
                        style="max-width:100%"
                      />
                    </div>
                    <div :class="item.img ? 'tab-left col-lg-6' : 'tab-left'">
                      <el-radio-group
                        v-model="selectedAns[itemIndex]"
                        :disabled="!isAuth"
                      >
                        <el-radio
                          class="col-md-12"
                          v-for="(ans, ansIndex) in answers[item.num]"
                          style="padding-bottom:2px; line-height: 2.0;"
                          :key="ansIndex"
                          :label="ansIndex + 1"
                        >
                          <span style="white-space: initial;">{{ ans }}</span>
                        </el-radio>
                      </el-radio-group>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-6 col-md-offset-2" style="margin-bottom:100px">
                <button
                  class="btn btn-primary btn-fill btn-wd"
                  @click="submitRiskResult"
                  :disabled="!isAuth"
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
  </base-layout>
</template>
<script>
import { ref, computed } from "vue";
import useAuth from "@/composables/auth";
import useUserData from "@/composables/userData";
import BaseLayout from "@/components/layouts/BaseLayout";
import { useRouter } from "vue-router";
import { useToast } from "vue-toastification";
export default {
  components: {
    BaseLayout,
  },
  methods: {
    ansAll: function() {
      this.$router.push({ name: "RiskResult" });
    },
  },
  setup() {
    const questions = [
      { num: "1", q: "How old are you?" },
      {
        num: "2",
        q: "What is the ratio of your monthly expense to your income?",
      },
      { num: "3", q: "What is your current financial status?" },
      {
        num: "4",
        q: `Which of the following assets do you have investment
          experience with or knowledge about?`,
      },
      {
        num: "5",
        q: `The estimated period that you will not need to use
          your invested funds`,
      },
      { num: "6", q: "What is your primary investment objective?" },
      {
        num: "7",
        q:
          "If you were to invest in high-return but high-risk assets, how would you feel?",
      },
      {
        num: "8",
        q:
          "You would be worried or unwilling to accept a scenario where your investment diminishes by:",
      },
      {
        num: "9",
        q:
          "What would you do if you invested 100,000 Baht last year, but the value of your investment declines to 85,000 Baht this year?",
      },
      {
        num: "10",
        q:
          "Successfully investing in derivatives and structure notes offers exceptionally high returns, but failure will probably lead to total loss where additional funds may be required to cover such loss. Are you willing to accept such an outcome?",
      },
      {
        num: "11",
        q:
          "In addition to the risk of investment, Can you withstand foreign exchange risk in case of investing in Funds withforeign investment portion higher than 20% of NAV?",
      },
      {
        num: "12",
        img: "riskSurveyImg7.png",
        q: `Which of the following investment scenarios are you willing
          to be engaged in? (See figure below)`,
      },
    ];

    const answers = {
      "1": [
        "Over 55 years",
        "45 – 55 years",
        "35 – 44 years",
        "Under 35 years",
      ],
      "2": ["Over 75%", "Between 51– 75%", "Between 25 – 50%", "Less than 25%"],
      "3": [
        "Less assets than debts",
        "Assets equal to debts",
        "More assets than debts",
        "I will have adequate savings/investments throughout my retirement",
      ],
      "4": [
        "Bank Deposit",
        "Government Bond or Government Bond Fund",
        "Debentures or Fixed Income Fund",
        "Equity Stock or Equity Fund or other High-Risk Assets",
      ],
      "5": [
        "Less than 1 year",
        "1 – 3 years",
        "3 –5 years",
        "More than 5 years",
      ],
      "6": [
        "To get returns with secure investment principal, although the return is lower or close to inflation rate",
        "To increase my chance of getting a returns that is a little over the inflation rate despite risk of partial investment loss",
        "To increase my chance of getting a higher returns over the inflation rate despite higher risk of investment loss",
        "To increase my chance of getting the maximum returns despite highest risk of investment loss",
      ],
      "7": [
        "Bank Deposit",
        "Government Bond or Government Bond Fund",
        "Debentures or Fixed Income Fund",
        "Equity Stock or Equity Fund or other High-Risk Assets",
      ],
      "8": ["5% or less", "Around 5% -10%", "Around 10% -20%", "Over 20%"],
      "9": [
        "Take fright, and demand to sell all investments in your portfolio",
        "Worry, and shift some investments to lower-risk assets",
        "Be patient, and hold the assets until their value rebounds",
        "Remain confident in your long-term investment plan, and invest more in the same asset typeb to reduce",
      ],
      "10": [
        "No",
        "Yes, but only to a certain extent",
        "Yes, without reservation",
      ],
      "11": [
        "No",
        "Yes, but only to a certain extent",
        "Yes, without reservation",
      ],
      "12": [
        "Scenario 1: maximum returns of perhaps 2.5%, and 0% loss",
        "Scenario 2: maximum returns of perhaps 7%, and 1% possible loss",
        "Scenario 3: maximum returns of perhaps 15%, and 5% possible loss",
        "Scenario 4: maximum returns of perhaps 25%, and 15% possible loss",
      ],
    };

    const selectedAns = ref([]);

    const { isAuth } = useAuth();
    const { SetRiskScore } = useUserData();

    const router = useRouter();
    const toast = useToast();

    const totalScore = computed(() =>
      selectedAns.value.length > 0 ? selectedAns.value.reduce(sum) : 0
    );

    function submitRiskResult() {
      SetRiskScore(totalScore.value).then(() => {
        toast.success(`Your risk level is updated`);
        router
          .push({
            name: "RiskResult",
            params: {
              riskScore: totalScore.value,
            },
          })
          .catch(err => {
            console.error("PUT risk score fail", err);
            toast.error(
              "Fail to update risk score. Cannot connect to a server."
            );
          });
      });
    }

    function sum(total, num) {
      return total + num;
    }

    return {
      questions,
      answers,
      selectedAns,
      isAuth,

      submitRiskResult,
    };
  },
};
</script>
<style>
.tab-left {
  margin-left: 30px;
}

.btn-submit {
  min-width: 35%;
  margin-top: 20px;
}
</style>
