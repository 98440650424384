<template>
  <apexchart
    type="area"
    height="350"
    :options="chartOptions"
    :series="navGraph"
  ></apexchart>
</template>

<script>
import { ref, watchEffect } from "vue";
import { useFundNAV } from "@/composables/fundNav";
export default {
  props: {
    fundID: {
      type: String,
      required: true,
    },
    navRange: {
      type: String,
      default: "3mo",
    },
    fundCode: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const chartOptions = ref({
      chart: {
        height: 350,
        zoom: {
          enabled: false,
        },
      },
      tooltip: {
        x: {
          format: "dd MMM yyyy",
        },
      },
      xaxis: {
        type: "datetime",
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: "straight",
      },
    });

    const { navFund, FetchNavSeries } = useFundNAV();

    function isNavIncrease(nav){
      if (nav.length == 0) return false
      return nav[nav.length - 1][1] > nav[0][1]
    }

    const navGraph = ref([
      {
        name: props.fundCode,
        data: navFund,
        color: isNavIncrease(navFund.value) ? "#41B883" : "#880808",
      },
    ]);

    async function updateNav(code, fundID, range) {
      await FetchNavSeries(fundID, range);
      navGraph.value = [
        {
          name: code,
          data: navFund.value,
          color: isNavIncrease(navFund.value) ? "#41B883" : "#880808",
        },
      ];
    }

    watchEffect(() => {
      updateNav(props.fundCode, props.fundID, props.navRange);
    });

    
    

    return {
      navGraph,
      chartOptions,
    };
  },

  
};
</script>
