<template>
  <div>
    <div class="col-md-12">
      <buy-fund-modal></buy-fund-modal>
      <sell-fund-modal></sell-fund-modal>
      <h3><b>Order History</b></h3>
      <br />
      <el-table
        class="table-striped"
        :data="transactions"
        border
        style="width: 100%"
        max-height="650"
      >
        <el-table-column
          v-for="column in tableColumns"
          :key="column.label"
          :min-width="column.minWidth"
          :prop="column.prop"
          :label="column.label"
        >
        </el-table-column>
        <el-table-column min-width="130" fixed="left" label="Order Date">
          <template #default="scope">
            <i class="el-icon-time"></i>
            <span style="margin-left: 10px">{{ scope.row.data_date }}</span>
          </template>
        </el-table-column>
        <el-table-column min-width="185" fixed="right" label="">
          <template #default="props">
            <el-button-group>
              <el-button
                size="small"
                @click="handleClickBuy(props.$index, props.row)"
                >Purchase
              </el-button>
              <el-button
                size="small"
                @click="handleClickSell(props.$index, props.row)"
                >Redeem
              </el-button>
            </el-button-group>
          </template>
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script>
import { onMounted, ref } from "vue";
import { useToast } from "vue-toastification";
import { getTransactions } from "@/resources/sim.api";
import {
  useFundTransactionBuy,
  useFundTransactionSell,
} from "@/composables/fundTransaction";
import { useFundNavScope } from "@/composables/fundNav";
import dayjs from "dayjs";
import Decimal from "decimal.js-light";
import BuyFundModal from "@/views/modals/BuyFundModal";
import SellFundModal from "@/views/modals/SellFundModal";
export default {
  components: {
    BuyFundModal,
    SellFundModal,
  },
  data() {
    return {
      tableColumns: [
        {
          prop: "type",
          label: "Type",
          minWidth: 100,
        },
        {
          prop: "code",
          label: "Fund",
          minWidth: 120,
        },
        {
          prop: "NAV",
          label: "NAV",
          minWidth: 80,
        },
        {
          prop: "amount",
          label: "Amount",
          minWidth: 100,
        },
        {
          prop: "unit",
          label: "Unit",
          minWidth: 100,
        },
        {
          prop: "timestamp",
          label: "Timestamp",
          minWidth: 170,
        },
      ],
    };
  },
  setup() {
    let transactions = ref([]);
    const toast = useToast();

    const { showBuyModal } = useFundTransactionBuy();
    const { showSellModal } = useFundTransactionSell();
    const { FetchNavLatestScope, SetGlobal } = useFundNavScope();

    function handleClickBuy(index, row) {
      FetchNavLatestScope(row.fund_id, "2w")
        .then(() => {
          SetGlobal();
          showBuyModal(row.fund_id, row.code, row.bcat_id);
        })
        .catch(err => {
          console.error(err);
          toast.error(`Cannot purchase ${row.code}`);
        });
    }

    function handleClickSell(index, row) {
      FetchNavLatestScope(row.fund_id, "2w")
        .then(() => {
          SetGlobal();
          showSellModal(row.fund_id, row.code, row.bcat_id);
          console.debug(`Redeem ${index}`, row);
        })
        .catch(err => {
          console.error("Failed to open redeem dialog ", err);
          toast.error(`Cannot redeem ${row.code}`);
        });
    }

    onMounted(async () => {
      try {
        const response = await getTransactions();
        const data = response.data;
        data.map(elem => {
          let type = "Unknown";
          if (elem.transaction_type == 1) type = "Purchase";
          else if (elem.transaction_type == 2) type = "Redeem";
          elem.type = type;
          elem.data_date = dayjs(elem.data_date).format("DD/MM/YY");
          elem.amount = "฿ " + new Decimal(elem.amount).toFixed(2);
          elem.unit = new Decimal(elem.unit).todp(4);
          elem.timestamp = dayjs(elem.timestamp).format("DD MMM YY - HH:mm:ss");
          return elem;
        });
        transactions.value = data;
      } catch (err) {
        console.error("Fetch order histroy failed", err);
        toast.warning("Fetch order histroy failed");
      }
    });

    return {
      handleClickBuy,
      handleClickSell,
      transactions,
    };
  },
};
</script>

<style>
.main-panel > .content {
  padding: 300px 15px 0 15px;
  min-height: calc(100% - 123px);
}
</style>
