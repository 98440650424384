<template>
  <base-layout isAuthPage>
    <template v-slot:content>
      <div class="wrapper wrapper-full-page">
        <div class="full-page login-page" data-color="" data-image="">
          <!--   you can change the color of the filter page using: data-color="blue | azure | green | orange | red | purple" -->
          <div class="content">
            <div class="container">
              <div class="row">
                <div class="col-md-6 col-sm-6 col-md-offset-3 col-sm-offset-3">
                  <div class="card-header">
                    <h1
                      style="text-align:center;color:#f1b93f;font-weight:bold;"
                    >
                      I N V E S T I O
                    </h1>
                    <h3 style="text-align:center;color:#f1b93f;margin-top:0">
                      Welcome
                    </h3>
                    <br /><br />
                  </div>
                  <div class="card-content">
                    <div class="form-group">
                      <input
                        placeholder="Enter username"
                        class="form-control input-no-border"
                        v-model="user"
                      />
                    </div>
                    <div class="form-group">
                      <input
                        type="password"
                        placeholder="Password"
                        class="form-control input-no-border"
                        v-model="pwd"
                        @keyup.enter="login"
                      />
                    </div>
                  </div>
                  <div class="card-footer text-center">
                    <br /><br />
                    <button
                      @click="login"
                      class="btn btn-primary btn-fill btn-wd"
                    >
                      Log in
                    </button>
                    <div class="forgot">
                      <el-popover
                        placement="bottom"
                        title="R.I.P. your account"
                        :width="260"
                        trigger="hover"
                        content="Don't forget it next time :P"
                      >
                        <template #reference>
                          <p>
                            <br />
                            <router-link
                              :to="{ name: 'Login' }"
                              style="color:white"
                            >
                              Forgot your password?
                            </router-link>
                          </p>
                        </template>
                      </el-popover>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- <div
            class="full-page-background"
            :style="{
              backgroundImage:
                'url(' +
                require('/static/img/background/background-2.jpg') +
                ')'
            :style="{
              backgroundImage:
                'url(' +
                'https://drive.google.com/uc?id=1fU3f31Q1Z2TF_xlSEtx07mDGuuT_tlyr' +
                ')'
            }"
            }"
          ></div> -->
          <div
            class="full-page-background"
            :style="{
              backgroundImage: 'url(' + `${bgImgPath}` + ')',
            }"
          ></div>
        </div>
      </div>
    </template>
  </base-layout>
</template>

<script>
import { ref } from "vue";
import BaseLayout from "@/components/layouts/BaseLayout.vue";
import useUserAPI from "@/resources/user.api";
import useAuth from "@/composables/auth";
import useFundWallet from "@/composables/fundWallet";
import useUserData from "@/composables/userData";
import { useToast } from "vue-toastification";
import { useRouter } from "vue-router";
import swAlert from "sweetalert2";
import useFundPort from "@/composables/fundPort";
export default {
  props: {
    redirect: {
      type: String,
      default: "",
    },
  },
  setup(props) {
    const publicPath = process.env.BASE_URL;
    const bgImgPath = ref("");
    const bgImgs = [
      `${publicPath}img/bg/fin-bg-1.jpg`,
      `${publicPath}img/bg/fin-bg-2.jpg`,
    ];
    bgImgPath.value = bgImgs[0];

    const { postLogin } = useUserAPI();
    const { setAuth } = useAuth();
    const { SetUserData } = useUserData();
    const { FetchBalances } = useFundWallet();
    const { FetchPort } = useFundPort();

    const router = useRouter();

    const user = ref("");
    const pwd = ref("");
    const toast = useToast();

    async function login() {
      try {
        const response = await postLogin(user.value, pwd.value);
        const data = response.data;
        await setAuth({
          acc: data.acc,
          ref: data.ref,
          a_exp: data.a_exp,
        });

        await FetchBalances();

        await FetchPort();

        SetUserData({
          username: data.username,
          id: data.uid,
        });

        toast.success(`Welcome ${data.username}`);

        if (props.redirect == "") router.go(-1);
        else router.push({ name: "Portfolio" });
      } catch (err) {
        console.error(err);
        try {
          if (err.response.status == 401) {
            swAlert.fire({
              title: "Fail to login",
              icon: "error",
              text: "Username or password is incorrected :(",
            });
          } else {
            console.error("API: ", err);
            swAlert.fire({
              title: "Error :(",
              icon: "error",
              text: "Please try again later.",
            });
          }
        } catch (err) {
          swAlert.fire({
            title: "Error :(",
            icon: "error",
            text: "Cannot connect to a server. Pls try again later",
          });
        }
      }
    }

    return { bgImgPath, user, pwd, login };
  },
  components: {
    BaseLayout,
  },
};
</script>
