import { createStore } from "vuex";

const state = {
  socket: {
    // Connection Status
    isConnected: false,
    // Message content
    message: "",
    // Reconnect error
    reconnectError: false,
  },

  fundSearchQueries: [],
};

const mutations = {
  // Connection open
  SOCKET_ONOPEN(state) {
    // main.config.globalProperties.$socket = event.currentTarget;
    state.socket.isConnected = true;
    state.reconnectError = false;
  },
  // Connection closed
  SOCKET_ONCLOSE(state) {
    state.socket.isConnected = false;
  },
  // An error occurred
  SOCKET_ONERROR(state, event) {
    console.error(state, event);
  },
  // Receive the message sent by the server
  SOCKET_ONMESSAGE(state, message) {
    state.socket.message = message;
    if ((message.type == "FUNDRES") & (message.topic == "search")) {
      state.fundSearchQueries = message.data;
    }
  },
  // Auto reconnect
  SOCKET_RECONNECT(state, count) {
    console.info("WS Reconnect...", state, count);
  },
  // Reconnect error
  SOCKET_RECONNECT_ERROR(state) {
    state.socket.reconnectError = true;
  },
};

// const actions = {
//   searchFund({ commit }) {
//     commit('')
//   }
// }

const getters = {
  fundSearchQueries: state => {
    return state.fundSearchQueries;
  },
};

export default createStore({
  state,
  mutations,
  // actions,
  getters,
  // modules: {}
});
