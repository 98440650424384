<template>
  <div>
    <label>Fund</label>
    <p>{{ fundCode }}</p>
  </div>
  <label>NAV as of {{ currentDate }}</label>
  <p>{{ currentNav }}</p>

  <el-divider></el-divider>
  <label>Wallet Balance</label>
  <p><small> &#3647;</small> {{ walletAvaliableBalance }}</p>

  <span v-if="showMinSubsequence">
    <label>Minimum sequence purchase</label>
  </span>
  <span v-else>
    <label>Minimum first time purchase</label>
  </span>
  <p>฿ {{ minBuyAmount }}</p>
  <label>Purchase Amount</label>
  <el-input
    placeholder="500.00"
    v-model="buyAmount"
    type="number"
    @change="setEditingForm"
    max="1000000000"
  >
    <template #prepend>THB</template>
  </el-input>

  <el-alert
    v-show="approxBuyUnit != 0"
    :title="'You will recieve ' + approxBuyUnit + ' unit'"
    type="info"
    effect="light"
    show-icon
    :closable="false"
  ></el-alert>
</template>

<script>
import { computed, toRefs } from "vue";
import { useFundTransactionBuy } from "@/composables/fundTransaction";
import useFundWallet from "@/composables/fundWallet";
import { useFundNAV } from "@/composables/fundNav";
import useAppState from "@/composables/appState";
import dayjs from "dayjs";
export default {
  name: "BuyStep1",

  setup() {
    const {
      buyAmount,
      fundInBuyOrder,
      minBuyAmount,
      showMinSubsequence,
      buyUnitDecimal,
    } = useFundTransactionBuy();
    const { walletAvaliableBalance } = useFundWallet();
    const { setEdit, isEditingForm } = useAppState();
    const { latestNavData, currentNav } = useFundNAV();

    function setEditingForm() {
      if (!isEditingForm.value) {
        console.debug("Set editing form");
        setEdit();
      }
    }

    const currentDate = dayjs(latestNavData.value.data_date).format(
      "DD/MM/YYYY"
    );

    const { fundID, fundCode } = toRefs(fundInBuyOrder);

    return {
      walletAvaliableBalance: computed(() =>
        Number(walletAvaliableBalance.value).toLocaleString()
      ),
      setEditingForm,
      buyAmount,
      approxBuyUnit: computed(() => buyUnitDecimal.value.todp(4).toString()),
      currentNav,
      currentDate,
      fundInBuyOrder,
      fundID,
      fundCode,
      minBuyAmount: computed(() => minBuyAmount.value.toFixed(2)),
      showMinSubsequence,
    };
  },
};
</script>
