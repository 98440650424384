<template>
  <router-view v-slot="{ Component }">
    <transition name="fade" mode="out-in">
      <component :is="Component" />
    </transition>
  </router-view>
</template>

<script>
import { httpClientWithAuth } from "@/resources/httpClients";
import useAuth from "@/composables/auth";
import useUserData from "@/composables/userData";
import useUserAPI from "@/resources/user.api";
import { onMounted } from "vue";
import { useToast } from "vue-toastification";
import useFundWallet from "@/composables/fundWallet";
import useFundPort from "@/composables/fundPort";

export default {
  setup() {
    // const { authState, clearAuth, getToken, setAuthFail } = useAuth();
    const { postRefresh } = useUserAPI();
    const { getToken, setAuth } = useAuth();
    const { SetUserData, FetchUserData } = useUserData();

    const { FetchBalances } = useFundWallet();
    const { FetchPort } = useFundPort();
    const toast = useToast();
    // const { isAuth, authError } = toRefs(authState);

    onMounted(async () => {
      try {
        const oldToken = await getToken();
        const response = await postRefresh(oldToken);
        const data = response.data;
        console.debug("Re Tokens: ", data);
        httpClientWithAuth.defaults.headers.common["Authorization"] =
          "Bearer " + data.acc;
        await setAuth(data);
        SetUserData({
          username: data.username,
          id: data.uid,
        });
        FetchUserData().catch(() => toast.warning("Fetch data failed"));
        FetchBalances().catch(() => toast.warning("Fetch balance failed"));
        FetchPort().catch(() => toast.warning("Fetch portfolio failed"));

        console.debug(`welcome back! ${data.username}`);
        toast.success(`Welcome back! ${data.username}`);
      } catch (err) {
        console.debug(err);
      }

      // // Request interceptor for API calls
      // // TODO: check a Token Exp
      // httpClientWithAuth.interceptors.request.use(
      //   async config => {
      //     const value = await redisClient.get(rediskey);
      //     const keys = JSON.parse(value);
      //     config.headers = {
      //       Authorization: `Bearer ${keys.access_token}`,
      //       Accept: "application/json",
      //       "Content-Type": "application/x-www-form-urlencoded",
      //     };
      //     return config;
      //   },
      //   error => {
      //     Promise.reject(error);
      //   }
      // );

      // Response interceptor for API calls
      httpClientWithAuth.interceptors.response.use(
        response => {
          return response;
        },
        async function(error) {
          const originalRequest = error.config;
          if (error.response.status === 403 && !originalRequest._retry) {
            originalRequest._retry = true;
            const oldToken = await getToken();
            try {
              const response = await postRefresh(oldToken);
              console.debug("Re Tokens: ", response.data);
              httpClientWithAuth.defaults.headers.common["Authorization"] =
                "Bearer " + response.data.acc;
              await setAuth(response.data);
              SetUserData({
                username: response.data.username,
                id: response.data.uid,
              });
              // await FetchBalances();
              return httpClientWithAuth(originalRequest);
            } catch (err) {
              return Promise.reject(err);
            }
          }
          return Promise.reject(error);
        }
      );
    });
  },
};
</script>

<style lang="scss">
@import "assets/static/css/themify-icons.css";
@import "https://fonts.googleapis.com/css?family=Muli:400,300";
@import "https://fonts.googleapis.com/css?family=Montserrat";
@import "https://maxcdn.bootstrapcdn.com/bootstrap/3.3.7/css/bootstrap.min.css";
@import "https://maxcdn.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css";

.notifications.vue-notifyjs {
  .notification-list-move {
    transition: transform 0.3s, opacity 0.4s;
  }
  .notification-list-item {
    display: inline-block;
    margin-right: 10px;
  }
  .notification-list-enter-active,
  .notification-list-leave-active {
    transition: opacity 0.4s;
  }
  .notification-list-enter,
  .notification-list-leave-to {
    /* .list-leave-active for <2.1.8 */
    opacity: 0;
  }
}
</style>
