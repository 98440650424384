<template>
  <base-layout isNavDark>
    <template v-slot:content>
      <buy-fund-modal></buy-fund-modal>
      <sell-fund-modal></sell-fund-modal>

      <div class="wrapper wrapper-full-page">
        <div class="register-page">
          <!--   you can change the color of the filter page using: data-color="blue | azure | green | orange | red | purple" -->
          <div class="content">
            <div class="container">
              <div class="row col-md-8 col-md-offset-2">
                <div
                  class="header-text"
                  style="padding-top:150px;text-align:left"
                >
                  <center><fund-search-box /></center>
                  <br />

                  <h2 class="header-fundinfo">{{ info.code }}</h2>
                  <h4 style="color:#999">{{ info.name_th }}</h4>

                  <br />
                  <div class="clearfix">
                    <div class="col-md-6" style="padding-left:0px">
                      <h2
                        class="header-fundinfo"
                        style="margin-top:20px;"
                        :class="navChangePercent > 0 ? 'green' : 'red'"
                      >
                        {{ latestNav.nav }}
                      </h2>
                      <h5>
                        <span :class="navChangePercent > 0 ? 'green' : 'red'"
                          >{{ navChange }} ( {{ navChangePercent }} % )</span
                        ><br />
                        {{ latestNavDate }}
                      </h5>
                    </div>

                    <span v-if="isAuth">
                      <div
                        class="col-md-6"
                        style="float:right;margin-top: 10px;"
                      >
                        <el-button-group style="float:right">
                          <el-button
                            type="primary"
                            round
                            @click="displayBuyModal"
                          >
                            Purchase
                          </el-button>
                          <el-button round @click="displaySellModal">
                            Redeem
                          </el-button>
                        </el-button-group>
                      </div>
                    </span>
                    <span v-else
                      ><div class="col-md-6" style="text-align:right">
                        <el-popover
                          placement="bottom"
                          :width="250"
                          trigger="hover"
                          :disabled="isAuth"
                        >
                          <p>Login to access a simulator</p>
                          <div style="text-align: right; margin: 0;">
                            <el-button
                              type="primary"
                              size="mini"
                              @click.prevent="pushToLoginPage"
                            >
                              Log in
                            </el-button>
                          </div>
                          <template #reference>
                            <el-button-group style="float:right">
                              <el-button type="primary" round disabled>
                                Purchase
                              </el-button>
                              <el-button round disabled>
                                Redeem
                              </el-button>
                            </el-button-group>
                          </template>
                        </el-popover>
                      </div>
                    </span>
                  </div>
                </div>
              </div>

              <div class="row">
                <el-radio-group v-model="selectedNavRange" size="small">
                  <el-radio-button label="2w">2 W</el-radio-button>
                  <el-radio-button label="1mo">1 M</el-radio-button>
                  <el-radio-button label="2mo">2 M</el-radio-button>
                  <el-radio-button label="3mo">3 M</el-radio-button>
                  <el-radio-button label="6mo">6 M</el-radio-button>
                  <el-radio-button label="1y">1 Y</el-radio-button>
                  <!-- <el-radio-button label="3y">3 Y</el-radio-button> -->
                </el-radio-group>
              </div>

              <div class="row">
                <nav-chart
                  :fundID="fundID"
                  :fundCode="fundCode"
                  :navRange="selectedNavRange"
                />
                <!-- <nav-chart :navTimestamp="navFund" :fundCode="fundCode" /> -->
              </div>

              <!-- -------------------------fund return and fundamental information ------------------------------------->
              <div class="row">
                <div class="col-md-9 col-md-offset-1" style="padding-top:40px;">
                  <h4>Fund Info</h4>
                </div>

                <div class="col-md-11 col-md-offset-1" style="padding-let:0">
                  <div class="col-md-6">
                    <div class="col-md-3" style="padding-let:0">
                      <h5>Factsheet</h5>
                      <h5>Type</h5>
                      <h5>Risk Level</h5>
                      <!-- <h5>Dividend</h5> -->
                    </div>
                    <div class="col-md-9" style="text-align:right">
                      <el-link :href="info.factsheet_url" target="_blank">
                        <button class="factsheet-btn">
                          Download Factsheet
                        </button>
                      </el-link>
                      <h5>{{ info.cat_name_en }}</h5>
                      <h5>{{ info.risk_th }}</h5>
                      <!-- <h5>&#8211;</h5> -->
                    </div>
                  </div>
                  <div
                    class="col-md-6"
                    style="padding-left:0;border-left: 1px solid rgb(228, 232, 234);"
                  >
                    <div class="col-md-4">
                      <h5>AMC</h5>
                      <h5>Net asset</h5>
                      <h5>Inception date</h5>
                      <!-- <h5>Invest strategy</h5> -->
                    </div>
                    <div class="col-md-6" style="text-align:right">
                      <h5>{{ info.amc_code }}</h5>
                      <!-- <h5>฿ {{ Number(info.inception_date).toLocaleString()  }}</h5> -->
                      <!-- <h5>฿ {{ latestNav.asset }}</h5> -->
                      <h5>฿ {{ Number(latestNav.asset).toLocaleString() }}</h5>
                      <h5>{{ inceptionDate }}</h5>
                      <!-- <h5>{{ timestamp }}</h5> -->
                      <!-- <h5 style="font: size 1.1em;">
                        Passive
                      </h5> -->
                    </div>
                  </div>
                </div>

                <!-- -------------------------end fund return and fundamental information ------------------------------------->
                <div
                  class="col-md-10 col-md-offset-1"
                  style="border: 1px solid #e4e8ea; text-align:center; padding: 20px 0px;margin-top:30px"
                >
                  <div
                    class="col-md-2 col-md-offset-2"
                    style="padding-right: 17%;padding-left: 0;"
                  >
                    <b><h4>Return</h4></b>
                    <h5></h5>
                  </div>
                  <div class="col-md-2">
                    <b
                      ><h4 :class="stat.total_return_3m > 0 ? 'green' : 'red'">
                        {{ stat.total_return_3m }} %
                      </h4></b
                    >
                    <h5>3M</h5>
                  </div>
                  <div
                    class="col-md-2"
                    style="border-left: 1px solid rgb(228, 232, 234);"
                  >
                    <b>
                      <h4 :class="stat.total_return_6m > 0 ? 'green' : 'red'">
                        {{ stat.total_return_6m }} %
                      </h4>
                    </b>
                    <h5>6M</h5>
                  </div>
                  <div
                    class="col-md-2"
                    style="border-left: 1px solid rgb(228, 232, 234);"
                  >
                    <b
                      ><h4 :class="stat.total_return_1y > 0 ? 'green' : 'red'">
                        {{ stat.total_return_1y }} %
                      </h4></b
                    >
                    <h5>1Y</h5>
                  </div>
                </div>
                <div class="col-md-4"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
  </base-layout>
</template>

<script>
import { watchEffect, ref, computed, toRefs } from "vue";
import BaseLayout from "@/components/layouts/BaseLayout";
import FundSearchBox from "@/components/shared/FundSearchBox";
// import ChartCard from "../components/shared/UI/cards/ChartCard";
import NavChart from "@/components/pages/fundInfo/NavChart";
import BuyFundModal from "@/views/modals/BuyFundModal";
import useFundInfo from "@/composables/fundInfo";
import {
  useFundTransactionBuy,
  useFundTransactionSell,
} from "@/composables/fundTransaction";
import { useFundNAV } from "@/composables/fundNav";
import { useRoute, useRouter } from "vue-router";
import {
  getFundInfo,
  getLatestNav,
  getFundStatInfo,
} from "@/resources/fund.api";
import useAuth from "@/composables/auth";
import { useToast } from "vue-toastification";
import SellFundModal from "@/views/modals/SellFundModal";
import Dayjs from "dayjs";

export default {
  components: {
    BaseLayout,
    FundSearchBox,
    NavChart,
    BuyFundModal,
    SellFundModal,
  },
  setup() {
    const { getFundID } = useFundInfo();
    const { showBuyModal } = useFundTransactionBuy();
    const { showSellModal } = useFundTransactionSell();
    const { authState } = useAuth();
    const { navChange, navChangePercent } = useFundNAV();
    const route = useRoute();
    const router = useRouter();
    const info = ref({});
    const latestNav = ref({});
    const stat = ref({});
    const fundID = ref("");
    const fundCode = computed(() => route.params.fundCode);
    // const navFund = ref([]);
    const selectedNavRange = ref("3mo");

    const { isAuth } = toRefs(authState);

    const toast = useToast();

    async function fetchFundInfo() {
      try {
        const response = await getFundInfo(fundID.value);
        console.debug("INFO: ", response);
        info.value = response.data;
      } catch (err) {
        console.error("ERR FetchINFO: ", err);
      }
    }

    async function fetchLatestNAV() {
      try {
        const response = await getLatestNav(fundID.value);
        latestNav.value = response.data;
      } catch (err) {
        console.error("Err Latest Nav: ", err);
        toast.warning("Fail: Get lastest NAV");
      }
    }

    async function fetchStatInfo() {
      try {
        const response = await getFundStatInfo(fundID.value);
        stat.value = response.data;
      } catch (err) {
        console.error("Err stat: ", err);
        toast.warning("Fail: Get stat");
      }
    }

    function displayBuyModal() {
      if (Object.keys(latestNav.value).length === 0)
        toast.warning("Unable to purchase: No NAV data");
      else showBuyModal(fundID.value, fundCode.value, info.value.brd_cat_id);
    }

    function displaySellModal() {
      if (Object.keys(latestNav.value).length === 0)
        toast.warning("Unable to redeem: No NAV data");
      else showSellModal(fundID.value, fundCode.value, info.value.brd_cat_id);
    }

    function pushToLoginPage() {
      router.push({ name: "Login" });
    }

    watchEffect(() => {
      if (route.name == "FundInfo") {
        fundID.value = getFundID(fundCode.value);
        fetchFundInfo();
        fetchLatestNAV();
        fetchStatInfo();
      }
    });

    return {
      info,
      isAuth,
      latestNav,
      stat,
      fundID,
      fundCode,
      selectedNavRange,
      navChange,
      navChangePercent,
      displayBuyModal,
      displaySellModal,
      pushToLoginPage,
      inceptionDate: computed(() =>
        info.value.inception_date != ""
          ? new Dayjs(info.value.inception_date).format("DD/MM/YYYY")
          : "-"
      ),
      latestNavDate: computed(() =>
        latestNav.value.date != ""
          ? new Dayjs(latestNav.value.date).format("DD/MM/YYYY")
          : "-"
      ),
    };
  },
};
</script>
<style scoped>
.header-fundinfo {
  font-weight: bold;
  color: #333;
  line-height: 0.6;
}

.clearfix::after {
  content: "";
  clear: both;
  display: table;
}
.green {
  color: #17c161;
}
.red {
  color: #af170c;
}
</style>
