import { httpClientWithAuth } from "@/resources/httpClients";

const END_POINT = "sim/v1";

const getPort = () => httpClientWithAuth.get(`${END_POINT}/port`);

const getWalletBal = () => httpClientWithAuth.get(`${END_POINT}/wallet`);

const getTransactions = () => httpClientWithAuth.get(`${END_POINT}/orders`);

const postBuyFund = (
  dataDate,
  portID,
  fundID,
  fundCode,
  amount,
  unit,
  nav,
  brdCatID
) =>
  httpClientWithAuth.post(`${END_POINT}/port/buy`, {
    date: dataDate,
    port_id: portID,
    fund_id: fundID,
    fund_code: fundCode,
    amount: amount,
    unit: unit,
    NAV: nav,
    bcat_id: brdCatID,
  });

const postSellFund = (
  dataDate,
  portID,
  fundID,
  fundCode,
  amount,
  unit,
  nav,
  brdCatID
) =>
  httpClientWithAuth.post(`${END_POINT}/port/sell`, {
    date: dataDate,
    port_id: portID,
    fund_id: fundID,
    fund_code: fundCode,
    amount: amount,
    unit: unit,
    NAV: nav,
    bcat_id: brdCatID,
  });

export { getPort, getWalletBal, getTransactions, postBuyFund, postSellFund };
