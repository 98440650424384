<template>
  <ul class="nav nav-mobile-menu">
    <li>
      <a
        href="javascript:void(0)"
        class="dropdown-toggle btn-magnify"
        data-toggle="dropdown"
      >
        <i class="ti-panel"></i>
        <p>Fund Recommendations</p>
      </a>
    </li>

    <li>
      <a href="javascript:void(0)" class="btn-rotate">
        <i class="ti-shift-right"></i>
        <p class="hidden-md hidden-lg">
          Log Out
        </p>
      </a>
    </li>
  </ul>
</template>
<script>
export default {};
</script>
