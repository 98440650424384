<template>
  <el-dialog
    :title="'REDEEM ' + sellFundCode"
    v-model="isSellModalVisible"
    width="65%"
    :before-close="closeSellModal"
    destroy-on-close
    center
  >
    <span>
      <el-steps :active="currentStep" finish-status="success" align-center>
        <el-step title="Step 1"></el-step>
        <el-step title="Step 2"></el-step>
      </el-steps>
      <el-divider />
    </span>

    <component :is="currentComponent"></component>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="prevStep">Back</el-button>
        <el-button type="primary" @click="nextStep">{{ nextBtnTxt }}</el-button>
        <!-- :disabled="!isEligibleToBuy" -->
        <!-- <el-button
          v-if="showConfirmBtn"
          type="primary"
          @click="confirmFundPurchase"
          >Confirm</el-button
        > -->
      </span>
    </template>
  </el-dialog>
</template>

<script>
import { computed } from "vue";
import { useFundTransactionSell } from "@/composables/fundTransaction";
import SellStep1 from "@/components/modals/SellFundModal/SellStep1";
import SellStep2 from "@/components/modals/SellFundModal/SellStep2";
export default {
  components: {
    SellStep1,
    SellStep2,
  },
  setup() {
    const {
      currentStep,
      prevStep,
      nextStep,
      isSellModalVisible,
      closeSellModal,
      sellFundCode,
    } = useFundTransactionSell();

    const TOTAL_STEPS = 1;

    const currentComponent = computed(() => {
      return `SellStep${currentStep.value + 1}`;
    });

    return {
      currentStep,
      prevStep,
      nextStep,
      currentComponent,
      isSellModalVisible,
      closeSellModal,
      sellFundCode,
      nextBtnTxt: computed(() =>
        currentStep.value < TOTAL_STEPS ? "Next" : "Confirm"
      ),
    };
  },
};
</script>
