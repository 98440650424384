<template>
  <base-layout isNavDark>
    <template v-slot:content>
      <div class="wrapper wrapper-full-page">
        <div class="register-page">
          <!--   you can change the color of the filter page using: data-color="blue | azure | green | orange | red | purple" -->
          <div class="content">
            <div class="container">
              <div class="row">
                <!--------------------- select one of the three pages ------------------------------>
                <div
                  class="guide col-md-8 col-md-offset-2"
                  style="margin-top: 15%;box-shadow: 0 4px 11px 0 rgb(0 0 0 / 15%);background-color: #fff;"
                >
                  <router-link :to="{ name: 'KnowledgeFund' }">
                    <div class="col-md-4 center kn-act-21">
                      <h5 class="margin-zero">Mutual fund</h5>
                    </div>
                  </router-link>

                  <router-link :to="{ name: 'KnowledgeRisk' }">
                    <div
                      class="col-md-4 center kn-act-22"
                      style="border-left: 2px solid #ececec"
                    >
                      <h5 class="margin-zero" style="color:black">
                        Investment Risk
                      </h5>
                    </div>
                  </router-link>

                  <router-link :to="{ name: 'KnowledgeNAV' }">
                    <div
                      class="col-md-4 center kn-act-23"
                      style="border-left: 2px solid #ececec"
                    >
                      <h5 class="margin-zero">NAV</h5>
                    </div>
                  </router-link>
                </div>

                <!---------------------------------------- start second section ---------------------------------------->

                <div class="col-md-8 col-md-offset-2">
                  <div class="header-text">
                    <h4 class="info-topic">
                      Investment Risks
                    </h4>
                    <hr
                      style="height:2px;border-width:0;color:black;background-color:black"
                    />
                    <h5 class="info-knowledge">
                      &emsp; &emsp; &emsp; &emsp; Once learning “what type of
                      investor” they are which can be done by
                      <router-link
                        :to="{ name: 'KnowledgeRisk' }"
                        class="risk-act"
                        style="color:#ffb41d"
                        ><b>risk survey</b></router-link
                      >
                      , the investor needs to “pick the mutual funds” that suit
                      his/her type.
                    </h5>
                    <h5 class="info-knowledge-div-minor">
                      <div style="color:black; font-weight:bold">
                        The mutual funds in Thailand are divided into
                      </div>
                      <div style="color:#ffb41d;font-weight:bold">
                        8 risk levels using risk spectrum in prospectus to
                        inform investors.
                      </div>
                    </h5>
                  </div>

                  <!-- mutual fund image -->
                  <div class="img-container">
                    <img
                      src="@/assets/static/img/fund-risk-level.png"
                      alt="Agenda"
                      style="max-width:100%;padding-bottom:30px"
                    />
                  </div>

                  <div class="col-lg-12 col-md-12">
                    <div class="card-tab">
                      <!-- <div class="card-header">
                        <h4 class="card-title">Tabs</h4>
                        </div> -->

                      <!-- @tab-click="handleClick" -->
                      <el-tabs v-model="activeTab" class="card-content">
                        <el-tab-pane label="Level 1" name="result">
                          <div id="containerIntro" style="text-align:justify;">
                            <h5 style="color:#327060;font-weight:900;">
                              Types of fund : Money market fund in Thailand
                            </h5>
                            <br /><br />
                            <h5 style="line-height:2.0;">
                              &emsp; &emsp; &emsp; &emsp;Has a policy to
                              specifically invest domestically in
                              deposit/bonds/other securities as indicated by the
                              SEC. The liabilities are payable on demand when
                              the fund reaches the maturity date, or when the
                              term of contract is less than a year. The
                              portfolio duration at a certain period is shorter
                              than three months.
                            </h5>
                          </div>
                        </el-tab-pane>
                        <el-tab-pane label="Level 2" name="level2">
                          <div id="containerIntro" style="text-align:justify;">
                            <h5 style="color:#3c8874;font-weight:bold;">
                              Types of fund : Money market fund
                            </h5>
                            <br /><br />
                            <h5 style="line-height:2.0;">
                              &emsp; &emsp; &emsp; &emsp;Has a policy to
                              partially invest overseas in deposit/bonds/other
                              securities indicated by the SEC, but no more than
                              50% of NAV. The liabilities are payable on demand
                              when the fund reaches the maturity date, or when
                              the term of contract is less than 397 days. The
                              portfolio duration at a certain period is shorter
                              than three months.
                            </h5>
                          </div>
                        </el-tab-pane>
                        <el-tab-pane label="Level 3" name="level3">
                          <div id="containerIntro" style="text-align:justify;">
                            <h5 style="color:#5fb9a1;font-weight:bold;">
                              Types of fund : Government bond fund
                            </h5>
                            <br /><br />
                            <h5 style="line-height:2.0;">
                              &emsp; &emsp; &emsp; &emsp;Has a policy to invest
                              in government bonds at least 80% of NAV on average
                              in each accounting period.
                            </h5>
                          </div>
                        </el-tab-pane>
                        <el-tab-pane label="Level 4" name="level4">
                          <div id="containerIntro" style="text-align:justify;">
                            <h5 style="color:#1dcfa7;font-weight:900;">
                              Types of fund : Fixed income fund
                            </h5>
                            <br /><br />
                            <h5 style="line-height:2.0;">
                              &emsp; &emsp; &emsp; &emsp;Has policy to invest in
                              general bonds.
                            </h5>
                          </div>
                        </el-tab-pane>
                        <el-tab-pane label="Level 5" name="level5">
                          <div id="containerIntro" style="text-align:justify;">
                            <h5 style="color:#ffc11e;font-weight:bold;">
                              Types of fund : Balanced fund
                            </h5>
                            <br /><br />
                            <h5 style="line-height:2.0;">
                              &emsp; &emsp; &emsp; &emsp;Has a policy to invest
                              in equity instruments and bonds
                            </h5>
                          </div>
                        </el-tab-pane>
                        <el-tab-pane label="Level 6" name="level6">
                          <div id="containerIntro" style="text-align:justify;">
                            <h5 style="color:#ff7a38;font-weight:bold;">
                              Types of fund : Equity fund
                            </h5>
                            <br /><br />
                            <h5 style="line-height:2.0;">
                              &emsp; &emsp; &emsp; &emsp;Has a policy to mainly
                              invest in equity instruments at least 65% of NAV
                              on average in each accounting period.
                            </h5>
                          </div>
                        </el-tab-pane>
                        <el-tab-pane label="Level 7" name="level7">
                          <div id="containerIntro" style="text-align:justify;">
                            <h5 style="color:#ef4f51;font-weight:bold;">
                              Types of fund : Sector fund
                            </h5>
                            <br /><br />
                            <h5 style="line-height:2.0;">
                              &emsp; &emsp; &emsp; &emsp;Has a policy to invest
                              in some specific sectors at least 80% of NAV on
                              average in each accounting period.
                            </h5>
                          </div>
                        </el-tab-pane>
                        <el-tab-pane label="Level 8" name="level8">
                          <div id="containerIntro" style="text-align:justify;">
                            <h5 style="color:#c11115;font-weight:bold;">
                              Types of fund : Alternative fund
                            </h5>
                            <br /><br />
                            <h5 style="line-height:2.0;">
                              &emsp; &emsp; &emsp; &emsp;Has a policy to invest
                              in alternative assets or assets with complicated
                              and incomprehensible structure, such as
                              commodities, gold fund, oil fund and non-hedging
                              derivatives. This includes structured notes
                              without principle protection.
                            </h5>
                          </div>
                        </el-tab-pane>
                      </el-tabs>
                    </div>
                  </div>
                </div>
                <!---------------------------------------- end of second section ---------------------------------------->
                <div class="col-md-8 col-md-offset-2">
                  <div class="header-text">
                    <button
                      type="button"
                      class="btn btn-fill btn-danger btn-wd"
                    >
                      <router-link to="/home"
                        ><a style="color:white">Back to home</a></router-link
                      >
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
  </base-layout>
</template>

<script>
import BaseLayout from "@/components/layouts/BaseLayout";
export default {
  components: {
    BaseLayout,
  },
  data() {
    return {
      activeTab: "result",
      series: [44, 55, 41, 17, 15],
      chartOptions: {
        chart: {
          type: "donut",
        },
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 200,
              },
              legend: {
                position: "bottom",
              },
            },
          },
        ],
      },
    };
  },
};
</script>
<style>
#containerIntro h5,
#containerIntro p {
  display: inline;
}

.kn-act-22 {
  background-color: #ffb41d;
}

.kn-act-21:hover {
  border-radius: 8px 0px 0px 8px;
  transition: all 0.4s;
  background-color: #ffb41d;
}

.kn-act-23:hover {
  background-color: #ffb41d;
  transition: all 0.4s;
  border-radius: 0px 8px 8px 0px;
}
</style>
