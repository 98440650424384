<template>
  <apexchart
    width="420"
    :options="chartOptions"
    :series="costPie"
    v-show="costPie.length > 0"
  ></apexchart>
</template>

<script>
import { ref, watchEffect, onMounted } from "vue";
import useFundPort from "@/composables/fundPort";
import useFundInfo from "@/composables/fundInfo";
export default {
  setup() {
    const costPie = ref([]);

    const { getCategoryByID, UpdateFundCats } = useFundInfo();

    const chartOptions = ref({});
    chartOptions.value = {
      chart: {
        type: "pie",
      },
      labels: [],
      responsive: [
        {
          breakpoint: 480,
          options: {
            legend: {
              position: "bottom",
            },
          },
        },
      ],
    };

    const { funds } = useFundPort();

    function groupBy(list, keyGetter) {
      const map = new Map();
      list.forEach(item => {
        const key = keyGetter(item);
        const collection = map.get(key);
        if (!collection) {
          map.set(key, [item]);
        } else {
          collection.push(item);
        }
      });
      return map;
    }

    onMounted(async () => {
      await UpdateFundCats();
    });

    watchEffect(() => {
      let costs = [];
      let catNames = [];
      const fundsInPort = funds.value.slice();

      const grouped = groupBy(fundsInPort, f => f.bcat_id);
      const keys = [...grouped.keys()];
      keys.forEach(k => {
        getCategoryByID(k).then(cat => {
          catNames.push(cat.name_en);
        });
      });

      grouped.forEach(elem => {
        let sumCost = 0;
        elem.forEach(f => {
          console.debug("F ", f);
          sumCost += Number(f.cost);
        });
        costs.push(sumCost);
      });
      costPie.value = costs;
      chartOptions.value = {
        ...chartOptions.value,
        labels: catNames,
      };
    });

    return {
      chartOptions,
      costPie,
    };
  },
};
</script>
