import { reactive, ref } from "vue";
import Decimal from "decimal.js-light";
import { postBuyFund, postSellFund, getPort } from "@/resources/sim.api";

const portID = ref("");
const portName = ref("");
const funds = ref([]);
const sumCost = ref(0);
const plRealized = ref(0);
const myPort = reactive({
  portID,
  portName,
  funds,
  sumCost,
  plRealized,
});

export default function useFundPort() {
  async function addFundToPort(
    fundID,
    fundCode,
    brdCatID,
    unitToBeAdded,
    amountToBeAdded,
    nav,
    dataDate
  ) {
    const newUnit = new Decimal(unitToBeAdded).todp(4).toNumber();
    const newAmount = new Decimal(amountToBeAdded).todp(2).toNumber();
    console.debug(
      "addFundPort",
      fundID,
      fundCode,
      brdCatID,
      unitToBeAdded,
      amountToBeAdded,
      nav,
      dataDate
    );

    await postBuyFund(
      dataDate,
      myPort.portID,
      fundID,
      fundCode,
      amountToBeAdded,
      unitToBeAdded,
      nav,
      brdCatID
    );

    let funds = myPort.funds;
    let fundIndex = funds.findIndex(obj => {
      return obj.fund_id === fundID;
    });
    // Check if fund is in port
    if (fundIndex == -1) {
      funds.push({
        fund_id: fundID,
        bcat_id: brdCatID,
        code: fundCode,
        cost: newAmount,
        unit: newUnit,
        pl_relized: 0, // relized_gain_loss
        pl_unrelized: 0,
      });

      // TODO: update pl, pl_percent

      console.debug(`Add new ${fundCode}`, funds);
    } else {
      console.debug(`Update ${fundCode}`, fundIndex);
      let fund = funds[fundIndex];
      fund["cost"] = new Decimal(fund["cost"])
        .plus(newAmount)
        .todp(2)
        .toNumber();
      fund["unit"] = new Decimal(fund["unit"])
        .plus(newUnit)
        .todp(4)
        .toNumber();
      // TODO: update pl, pl_percent

      funds[fundIndex] = fund;
    }

    myPort.funds = funds;
    myPort.sumCost = new Decimal(myPort.sumCost).plus(newAmount).toFixed(2);
  }

  async function FetchPort() {
    try {
      const response = await getPort();
      const data = response.data;
      myPort.portID = data.port_id;
      myPort.portName = data.port_name;
      myPort.plRealized = data.pl_relized;
      myPort.sumCost = data.sum_cost;
      myPort.funds = data.funds;
      console.debug("port ", myPort);
    } catch (err) {
      console.error(err);
      throw err;
    }
  }

  function getFundInPort(fundCode) {
    return myPort.funds.find(elem => elem.code == fundCode);
  }

  function isFundInPort(fundCode) {
    return myPort.funds.some(function(element) {
      return element.code == fundCode;
    });
  }

  async function sellFund(
    fundID,
    fundCode,
    brdCatID,
    unitToBeAdded,
    amountToBeAdded,
    nav,
    dataDate
  ) {
    console.debug(
      "SF ",
      fundID,
      fundCode,
      brdCatID,
      unitToBeAdded,
      amountToBeAdded,
      nav,
      dataDate
    );
    await postSellFund(
      dataDate,
      myPort.portID,
      fundID,
      fundCode,
      amountToBeAdded,
      unitToBeAdded,
      nav,
      brdCatID
    );

    let funds = myPort.funds;
    // const fund = getFundInPort(fundCode)
    let fundIndex = funds.findIndex(obj => {
      return obj.fund_id === fundID;
    });
    // Check if fund is in port
    if (fundIndex == -1) {
      console.error("redeem locally failed: please refresh page");
      return;
    }
    let fund = funds[fundIndex];
    fund["cost"] = new Decimal(fund["cost"])
      .minus(amountToBeAdded)
      .todp(2)
      .toNumber();
    fund["unit"] = new Decimal(fund["unit"])
      .minus(unitToBeAdded)
      .todp(4)
      .toNumber();
    // TODO: update pl, pl_percent

    funds[fundIndex] = fund;

    myPort.funds = funds;
    myPort.sumCost = new Decimal(myPort.sumCost)
      .sub(amountToBeAdded)
      .toFixed(2);
  }

  return {
    myPort,
    addFundToPort,
    FetchPort,
    getFundInPort,
    isFundInPort,
    sellFund,
    funds,
  };
}
