import { computed, ref } from "vue";

const isEditing = ref(false);

export default function useAppState() {
  function setEdit() {
    isEditing.value = true;
  }

  function resetEdit() {
    isEditing.value = false;
  }

  return {
    isEditingForm: computed(() => isEditing.value),
    setEdit,
    resetEdit,
  };
}
