<template>
  <base-layout isNavDark>
    <template v-slot:content>
      <div class="wrapper wrapper-full-page">
        <div class="register-page">
          <!--   you can change the color of the filter page using: data-color="blue | azure | green | orange | red | purple" -->
          <div class="content">
            <div class="container">
              <div class="row">
                <!--------------------- select one of the three pages ------------------------------>
                <div
                  class="guide col-md-8 col-md-offset-2"
                  style="margin-top: 15%;box-shadow: 0 4px 11px 0 rgb(0 0 0 / 15%);background-color: #fff;"
                >
                  <router-link :to="{ name: 'KnowledgeFund' }">
                    <div class="col-md-4 center kn-act-31">
                      <h5 class="margin-zero">Mutual fund</h5>
                    </div>
                  </router-link>

                  <router-link :to="{ name: 'KnowledgeRisk' }">
                    <div
                      class="col-md-4 center kn-act-32"
                      style="border-left: 2px solid #ececec"
                    >
                      <h5 class="margin-zero">Investment Risk</h5>
                    </div>
                  </router-link>

                  <router-link :to="{ name: 'KnowledgeNAV' }">
                    <div
                      class="col-md-4 center kn-act-33"
                      style="border-left: 2px solid #ececec"
                    >
                      <h5 class="margin-zero" style="color:black">NAV</h5>
                    </div>
                  </router-link>
                </div>
                <!---------------------------------------- start third section ---------------------------------------->

                <div class="col-md-8 col-md-offset-2">
                  <div class="header-text">
                    <h4 class="info-topic">
                      Net Asset Value (NAV)
                    </h4>
                    <hr
                      style="height:2px;border-width:0;color:black;background-color:black"
                    />
                    <h5 class="info-knowledge-div-minor">
                      To provide a clearer picture of Net Asset Value (NAV), a
                      day after the fund makes an investment (excluding the
                      funds invested overseas), it conducts a NAV valuation.
                    </h5>
                  </div>

                  <div class="img-container">
                    <img
                      src="@/assets/static/img/nav-calc.png"
                      alt="Agenda"
                      style="max-width:100%;padding-bottom:0px"
                    />
                  </div>

                  <div class="header-text">
                    <h4 class="info-topic" style="color:rgb(253, 188, 18);">
                      How the fund charges the fee
                    </h4>
                    <h5 class="info-knowledge">
                      &emsp; &emsp; &emsp; &emsp; NAV per unit is the fund’s
                      trading price. When NAV increases, the fund will gain more
                      assets. However, an increasing or decreasing NAV does not
                      indicate that the fund yields a high or low return. Below
                      is an example.
                    </h5>
                  </div>

                  <div class="col-md-7">
                    <div class="img-container">
                      <img
                        src="@/assets/static/img/nav.png"
                        alt="Agenda"
                        style="max-width:100%"
                      />
                    </div>
                  </div>
                  <div class="col-md-5">
                    <h5 class="info-knowledge" style="padding-bottom:20px">
                      Fund A has 12 THB of NAV per unit, while Fund B has 8 THB
                      of NAV per unit. We cannot tell that Fund B is more
                      attractive or tends to yield a higher return than Fund A
                      because its assets cost less
                    </h5>
                  </div>
                  <hr class="element-divider" />
                  <div class="header-text" style="padding-top:30px">
                    <h5
                      style="text-align:center;color: rgb(253, 188, 18);font-weight:bold"
                    >
                      NAV of some funds may go up, down and up again as a cycle
                    </h5>
                    <h5 style="text-align:center;color: black;font-weight:bold">
                      It does not mean the fund manager does not do a good job
                    </h5>
                  </div>
                </div>
                <!---------------------------------------- end of third section ---------------------------------------->
                <div class="col-md-8 col-md-offset-2">
                  <div class="header-text">
                    <button
                      type="button"
                      class="btn btn-fill btn-danger btn-wd"
                    >
                      <router-link to="/home"
                        ><a style="color:white">Back to home</a></router-link
                      >
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
  </base-layout>
</template>

<script>
import BaseLayout from "@/components/layouts/BaseLayout";
export default {
  components: {
    BaseLayout,
  },
  data() {
    return {
      activeTab: "result",
      series: [44, 55, 41, 17, 15],
      chartOptions: {
        chart: {
          type: "donut",
        },
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 200,
              },
              legend: {
                position: "bottom",
              },
            },
          },
        ],
      },
    };
  },
};
</script>
<style>
#containerIntro h5,
#containerIntro p {
  display: inline;
}

.kn-act-33 {
  background-color: #ffb41d;
  border-radius: 0px 8px 8px 0px;
}

.kn-act-31:hover {
  background-color: #ffb41d;
  transition: all 0.4s;
  border-radius: 8px 0px 0px 8px;
}

.kn-act-32:hover {
  background-color: #ffb41d;
  transition: all 0.4s;
}
</style>
