import Sidebar from "@/components/shared/sidebars/SideBar";
import SidebarItem from "@/components/shared/sidebars/SideBarItem";

const SidebarStore = {
  showSidebar: false,
  sidebarLinks: [],
  isMinimized: false,
  displaySidebar(value) {
    this.showSidebar = value;
  },
  toggleMinimize() {
    document.body.classList.toggle("sidebar-mini");
    // we simulate the window Resize so the charts will get updated in realtime.
    const simulateWindowResize = setInterval(() => {
      window.dispatchEvent(new Event("resize"));
    }, 180);

    // we stop the simulation of Window Resize after the animations are completed
    setTimeout(() => {
      clearInterval(simulateWindowResize);
    }, 1000);

    this.isMinimized = !this.isMinimized;
  },
};

const SidebarPlugin = {
  install: (app, options) => {
    if (options && options.sidebarLinks) {
      SidebarStore.sidebarLinks = options.sidebarLinks;
    }

    //   {
    // if (options && options.sidebarLinks) {
    //   SidebarStore.sidebarLinks = options.sidebarLinks;
    // }
    app.mixin({
      data() {
        return {
          sidebarStore: SidebarStore,
        };
      },
    });

    app.config.globalProperties.$sidebar = SidebarStore;

    // Object.defineProperty(app.config.globalProperties, "$sidebar", {
    //   get() {
    //     return app.SidebarStore;
    //   }
    // });
    // app.provide("$sidebar", SidebarStore);
    app.component("side-bar", Sidebar);
    app.component("sidebar-item", SidebarItem);
  },
};

export default SidebarPlugin;
