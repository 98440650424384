<template>
  <div class="card col-md-12">
    <form class="form-horizontal">
      <div class="card-content">
        <fieldset>
          <div class="col-sm-12 col-lg-10 col-lg-offset-1">
            <h2 style="fmargin:60px 0px 10px 0px;font-weight: 900;">
              Top 50 Funds
            </h2>
            <h4 class="zero-top sixty-bottom ">
              Based on past performance
            </h4>
          </div>

          <div class="form-group" style="margin-bottom: 0px;color: #909399;">
            <label class="col-sm-2 control-label">Filter by</label>
            <div class="col-sm-3">
              <el-cascader
                class="width-full"
                :options="catCascader"
                :props="catCascaderConfig"
                :show-all-levels="false"
                v-model="selectedCat"
                placeholder="All Categories"
                collapse-tags
                clearable
              >
                <template #default="{ node, data }">
                  <span>{{ data.label }}</span>
                  <span v-if="!node.isLeaf">
                    ({{ data.children.length }})
                  </span>
                </template>
              </el-cascader>
            </div>
            <div class="col-sm-3">
              <el-select
                v-model="selectedAmc"
                clearable
                placeholder="All AMCs"
                class="width-full"
              >
                <el-option
                  v-for="item in fundAmcs"
                  :key="item.amc_code"
                  :label="item.amc_code"
                  :value="item.amc_code"
                >
                </el-option>
              </el-select>
            </div>
            <div class="col-sm-3">
              <el-select v-model="selectedRisk" class="width-full">
                <el-option
                  v-for="item in riskLevels"
                  :key="item.risk"
                  :label="item.label"
                  :value="item.risk"
                >
                </el-option>
              </el-select>
            </div>
          </div>
        </fieldset>
      </div>
    </form>
    <!------------------------------ fund table ------------------------------------------------>
    <div class="card-content row">
      <div class="col-sm-12 col-lg-10 col-lg-offset-1">
        <!-- TODO: is loading status -->
        <el-table
          class="table-striped pointer"
          :data="pagedData"
          @row-click="handleRowClick"
        >
          <el-table-column
            width="65"
            prop="count"
            label=""
            fixed="left"
          ></el-table-column>
          <el-table-column
            min-width="170"
            prop="code"
            label="Fund code"
            fixed="left"
          >
          </el-table-column>
          <el-table-column label="Time frame">
            <el-table-column label="" width="40">
              <template v-slot:default="props">
                <span
                  v-if="
                    isBetterThanAvg(
                      props.row.total_return_1y,
                      props.row.total_return_avg_1y
                    )
                  "
                  >👍</span
                >
                <span v-else>😞</span>
              </template>
            </el-table-column>
            <el-table-column label="1 Year" min-width="100">
              <template v-slot:default="props">
                <span :class="props.row.total_return_1y > 0 ? 'green' : 'red'">
                  {{ props.row.total_return_1y }} %
                </span>
              </template>
            </el-table-column>
            <el-table-column label="" width="40">
              <template v-slot:default="props">
                <span
                  v-if="
                    isBetterThanAvg(
                      props.row.total_return_6m,
                      props.row.total_return_avg_6m
                    )
                  "
                  >👍</span
                >
                <span v-else>😞</span>
              </template>
            </el-table-column>
            <el-table-column label="6 Months" min-width="100">
              <template v-slot:default="props">
                <span :class="props.row.total_return_6m > 0 ? 'green' : 'red'">
                  {{ props.row.total_return_6m }} %
                </span>
              </template>
            </el-table-column>
            <el-table-column label="" width="40">
              <template v-slot:default="props">
                <span
                  v-if="
                    isBetterThanAvg(
                      props.row.total_return_3m,
                      props.row.total_return_avg_3m
                    )
                  "
                  >👍</span
                >
                <span v-else>😞</span>
              </template>
              <!-- prop="total_return_avg_3m"  -->
            </el-table-column>
            <el-table-column label="3 Months" min-width="100">
              <template v-slot:default="props">
                <span :class="props.row.total_return_3m > 0 ? 'green' : 'red'">
                  {{ props.row.total_return_3m }} %
                </span>
              </template>
            </el-table-column>
          </el-table-column>
          <el-table-column :min-width="50" fixed="right" label="">
            <template v-slot:default="props">
              <a
                class="btn btn-simple btn-xs btn-warning btn-icon edit"
                @click="handleRowClick(props.row, null, null)"
              >
                <i class="ti-stats-up"></i>
              </a>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="col-sm-5 col-lg-4 col-lg-offset-1 pagination-info">
        <p class="category" v-show="pagination.total > 0">
          Showing {{ from + 1 }} to {{ to }} of {{ pagination.total }} entries
        </p>
      </div>
      <div class="col-sm-7 col-lg-6 col-lg-offset-right-1">
        <p-pagination
          class="pull-right"
          v-model="pagination.currentPage"
          :per-page="pagination.perPage"
          :total="pagination.total"
        >
        </p-pagination>
      </div>
    </div>
    <!------------------------------end fund table ------------------------------------------------>
  </div>
</template>

<script>
import { ref, reactive, computed, watch, watchEffect, onMounted } from "vue";

import PPagination from "@/components/shared/Pagination.vue";
import useFundInfo from "@/composables/fundInfo";
import { useRouter } from "vue-router";
import { useToast } from "vue-toastification";

export default {
  name: "FundTopReturn",
  components: {
    PPagination,
  },
  setup() {
    const selectedCat = ref([]);
    const selectedAmc = ref("");
    const selectedTimeFrame = ref("1y");
    const selectedRisk = ref(6);

    const toast = useToast();

    const router = useRouter();
    const {
      fundAmcs,
      catCascader,
      topReturn,
      FetchAmcs,
      UpdateFundCats,
      FetchTopReturn,
    } = useFundInfo();

    const catCascaderConfig = {
      expandTrigger: "hover",
    };

    const returnTimeFrames = [{ time: "1y", label: "Past 1 Year" }];

    const riskLevels = [
      { risk: 2, label: "Low risk" },
      { risk: 6, label: "Moderate risk" },
      { risk: 9, label: "High risk" },
    ];

    const pagination = reactive({
      perPage: 10,
      currentPage: 1,
      total: topReturn.value.length,
    });
    const tableColumns = ref([
      {
        prop: "total_return_1y",
        label: "Return 1Y (%)",
        minWidth: 100,
      },
      {
        prop: "total_return_avg_1y",
        label: "Avg. Return 1Y (%)",
        minWidth: 120,
      },
      {
        prop: "peer_comp",
        label: "Peer comparison",
        minWidth: 100,
      },
      {
        prop: "data_date",
        label: "Updated",
        minWidth: 70,
      },
    ]);

    const from = computed(
      () => pagination.perPage * (pagination.currentPage - 1)
    );
    const to = computed(() => {
      let highBound = from.value + pagination.perPage;
      if (pagination.total < highBound) {
        highBound = pagination.total;
      }
      return highBound;
    });
    const pagedData = ref(topReturn.value.slice(from.value, to.value));

    function handleRowClick(row, col, event) {
      console.debug(col, event);
      router.push({ name: "FundInfo", params: { fundCode: row.code } });
    }

    onMounted(async () => {
      // update required info for TopReturn
      try {
        await FetchAmcs();
        await UpdateFundCats();
      } catch (err) {
        toast.error("Fetching data failed: AMC / Fund Category");
      }
    });

    watch([topReturn, pagination], ([,], [,]) => {
      if (topReturn.value) {
        pagination.total = topReturn.value.length;
        pagedData.value = topReturn.value.slice(from.value, to.value);
      } else {
        pagination.total = 0;
        pagedData.value = [];
      }
    });

    watchEffect(() => {
      let cat = selectedCat.value;
      if (cat != null) cat = cat[cat.length - 1];
      FetchTopReturn(cat, selectedTimeFrame, selectedAmc, selectedRisk);
      updateTableCol(selectedTimeFrame);
    });

    function updateTableCol(timeStr) {
      if (timeStr == "1y") {
        tableColumns.value = [
          {
            prop: "id",
            label: "",
            minWidth: 50,
          },
          {
            prop: "code",
            label: "Fund Code",
            minWidth: 70,
          },
          {
            prop: "total_return_1y",
            label: "Return 1Y (%)",
            minWidth: 100,
          },
          {
            prop: "total_return_avg_1y",
            label: "Avg. Return 1Y (%)",
            minWidth: 100,
          },
        ];
      } else if (timeStr == "6m") {
        tableColumns.value = [
          {
            prop: "code",
            label: "Fund Code",
            minWidth: 70,
          },
          {
            prop: "total_return_6m",
            label: "Return (%)",
            minWidth: 100,
          },
          {
            prop: "total_return_avg_6m",
            label: "Avg. Return (%)",
            minWidth: 100,
          },
        ];
      }
    }

    const yearTxt = computed(() => {
      if (selectedTimeFrame.value == "3y") return "3 Year";
      if (selectedTimeFrame.value == "1y") return "1 Year";
      if (selectedTimeFrame.value == "6m") return "6 Months";
      if (selectedTimeFrame.value == "3m") return "3 Months";
      return "";
    });

    const SetTimeframe = time => {
      selectedTimeFrame.value = time;
    };

    const isBetterThanAvg = (totalReturn, avgReturn) => {
      try {
        return Number(totalReturn) > Number(avgReturn);
      } catch (e) {
        console.error("Error while comparing avg in top return");
        return "-";
      }
    };

    return {
      pagination,
      tableColumns,
      from,
      to,
      pagedData,
      handleRowClick,
      catCascader,
      fundAmcs,
      topReturn,
      selectedCat,
      selectedAmc,
      selectedTimeFrame,
      returnTimeFrames,
      catCascaderConfig,
      selectedRisk,
      riskLevels,
      yearTxt,
      SetTimeframe,
      isBetterThanAvg,
    };
  },
};
</script>

<style scoped>
.green {
  color: #17c161;
  font-weight: bold;
}

.red {
  color: #af170c;
}
.pointer {
  cursor: pointer;
}
</style>
