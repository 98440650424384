import { computed, ref } from "vue";
import Decimal from "decimal.js-light";
import { useToast } from "vue-toastification";
import { getWalletBal } from "@/resources/sim.api";

let avaliableBalance = ref(new Decimal(0));
let inOrderBalance = ref(new Decimal(0));
let inAssetBalance = ref(new Decimal(0)); // COST
let totalSpend = ref(new Decimal(0));

const MAX_BALANCE = new Decimal("1e12");

export default function useFundWallet() {
  const toast = useToast();

  function BalanceNegativeException() {
    this.message = "Balance is less than 0";
    // Use V8's native method if available, otherwise fallback
    if ("captureStackTrace" in Error)
      Error.captureStackTrace(this, BalanceNegativeException);
    else this.stack = new Error().stack;
  }

  function Topup(amount) {
    if (!isAbleToTopup(amount)) {
      console.error(
        `Not able to topup: ${amount} | avalBal: ${avaliableBalance.value} | inOrderBal: ${inOrderBalance.value} | inAssetBal: ${inAssetBalance.value}`
      );
      return;
    }

    avaliableBalance.value = avaliableBalance.value.plus(amount);
    console.debug(`TOPUP: ${amount} -> ${avaliableBalance.value.toNumber()}`);
  }

  function ShowAmountToBeTopUp(amount) {
    if (!isAbleToTopup(amount)) return "";

    return avaliableBalance.value.lessThan(amount)
      ? new Decimal(amount).minus(avaliableBalance.value).toFixed(2)
      : "";
  }

  function isAbleToTopup(amount) {
    if (amount == "") return false;
    let newBalance = null;
    try {
      newBalance = avaliableBalance.value
        .plus(inOrderBalance.value)
        .plus(inAssetBalance.value)
        .plus(amount);
    } catch (err) {
      if (err instanceof Error && /DecimalError/.test(err.message)) {
        toast.warning("Format of amount must be numeric only");
        console.warn("Format of amount must be numeric only");
      } else {
        console.error(err);
      }
      return false;
    }
    // If wallet balace will exceed thredshold after this topup
    if (!newBalance.lessThanOrEqualTo(MAX_BALANCE)) {
      const promptStr = `Balance will be ฿ ${newBalance
        .toNumber()
        .toLocaleString()} which is exceed the limit ฿ ${MAX_BALANCE.toNumber().toLocaleString()}`;
      toast.warning(promptStr);
      console.warn(promptStr);
      return false;
    }
    return true;
  }

  function CheckPurchaseEligibility(amount) {
    return !avaliableBalance.value.lessThan(amount);
  }

  function AddInOrder(amount, fundCode) {
    avaliableBalance.value = avaliableBalance.value.minus(amount);
    inOrderBalance.value = inOrderBalance.value.plus(amount);
    console.debug(`inorder ${fundCode}`);
  }

  function PurchaseLocal(amount, fundCode) {
    console.debug(`Purchase ${fundCode} for ${amount}`);
    avaliableBalance.value = avaliableBalance.value.sub(amount);
    inAssetBalance.value = inAssetBalance.value.plus(amount);
    totalSpend.value = totalSpend.value.plus(amount);
  }

  function Redeem(amount, fundCode) {
    console.debug(`Receive ${amount}: ${fundCode}`);
    avaliableBalance.value.plus(amount);
    inAssetBalance.value.minus(amount);
  }

  function Withdraw(amount, fundCode) {
    // if avaliableBalance - newAmount <= 0
    if (!avaliableBalance.value.lessThan(amount))
      throw BalanceNegativeException();

    avaliableBalance.value.minus(amount);
    console.debug(`Withdraw money: ${fundCode}`);
  }

  async function FetchBalances() {
    console.debug("FETCH BAL");
    try {
      const response = await getWalletBal();
      const data = response.data;
      avaliableBalance.value = new Decimal(data.avalible_bal);
      inOrderBalance.value = new Decimal(data.inorder_bal);
      inAssetBalance.value = new Decimal(data.inasset_bal);
      totalSpend.value = new Decimal(data.total_spend);
    } catch (err) {
      console.error("Fetch wallet failed ", err);
      toast.error("Fetch wallet failed");
    }
  }

  return {
    walletAvaliableBalance: computed(() => avaliableBalance.value.toFixed(2)),
    walletInOrderBalance: computed(() => inOrderBalance.value.toFixed(2)),
    walletInAssetBalance: computed(() => inAssetBalance.value.toFixed(2)),
    walletTotalSpend: computed(() => totalSpend.value.toFixed(2)),
    avaliableBalance,
    Topup,
    Withdraw,
    PurchaseLocal,
    Redeem,
    AddInOrder,
    ShowAmountToBeTopUp,
    FetchBalances,
    CheckPurchaseEligibility,
  };
}
