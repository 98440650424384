import { httpClient } from "./httpClients";

const END_POINT = "public/v1/funds";

const getFundInfo = fundID => httpClient.get(`${END_POINT}/info/${fundID}`);
const getAllCategories = () => httpClient.get(`${END_POINT}/cats`);
const getAllAmcs = () => httpClient.get(`${END_POINT}/amcs`);
const getTopReturn = (fundCat, dataRange, amc, risk) =>
  httpClient.get(`${END_POINT}/top/return`, {
    params: {
      cat: fundCat,
      range: dataRange,
      amc: amc,
      risk: risk,
    },
  });

const getSearchFund = queryStr =>
  httpClient.get(`${END_POINT}/search/${queryStr}`);

const getLatestNav = fundID =>
  httpClient.get(`${END_POINT}/nav`, {
    params: {
      f: fundID,
    },
  });

const getNavByDate = (fundID, date) =>
  httpClient.get(`${END_POINT}/nav`, {
    params: {
      f: fundID,
      date: date,
    },
  });

const getNavSeries = (fundID, range) =>
  httpClient.get(`${END_POINT}/nav/series`, {
    params: {
      f: fundID,
      range: range,
    },
  });

const getFundStatInfo = fundID => httpClient.get(`${END_POINT}/stat/${fundID}`);

export {
  getFundInfo,
  getAllCategories,
  getAllAmcs,
  getTopReturn,
  getSearchFund,
  getLatestNav,
  getNavByDate,
  getNavSeries,
  getFundStatInfo,
};
