import { createApp } from "vue";
import App from "./App.vue";
// import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import ElementPlus from "element-plus";

import SideBar from "@/plugins/sidebarPlugin";
import sidebarLinks from "@/plugins/sidebarLinks";

import "@/assets/sass/paper-dashboard.scss";
import "@/assets/sass/element_variables.scss";
import "@/assets/sass/demo.scss";

import VueApexCharts from "vue3-apexcharts";
import PerfectScrollbar from "vue3-perfect-scrollbar";
import "vue3-perfect-scrollbar/dist/vue3-perfect-scrollbar.css";

import { openDB } from "idb";

openDB("fund", 2, {
  upgrade(db, oldVer, newVer, dbTransaction) {
    switch (oldVer) {
      case 0:
        upgradeFundFromV0toV1();
      // falls through
      case 1:
        upgradeFundFromV1toV2();
        break;
      default:
        console.error("Unknown DB Ver: fund");
    }

    function upgradeFundFromV0toV1() {
      // db.createObjectStore("cat", { keyPath: "fund_id" });
      db.createObjectStore("info", { keyPath: "fund_id" });
      db.createObjectStore("port", { autoIncrement: true });

      dbTransaction.objectStore("info").createIndex("fundcode", "code");
      dbTransaction.objectStore("info").createIndex("fundname_en", "name_en");
      dbTransaction.objectStore("info").createIndex("fundname_th", "name_th");
      dbTransaction.objectStore("port").createIndex("portname", "port_name");
      // TODO: Req port from server when log in
      dbTransaction.objectStore("port").add({
        port_id: "", // TODO: Get FROM SERVER
        port_name: "My first port",
        funds: [],
        // Port level stat
        total_cost: 0,
        pl: 0,
        pl_percent: 0,
        last_updated: Date.now(),
      });
    }

    function upgradeFundFromV1toV2() {
      db.createObjectStore("brdCategories", { autoIncrement: true });
      db.createObjectStore("categories", { keyPath: "cid" });
      db.createObjectStore("latestNAV", { keyPath: "fund_id" });

      dbTransaction.objectStore("brdCategories").createIndex("bid", "bid");
    }
  },
});

openDB("userPref", 1, {
  upgrade(db, oldVer, newVer, dbTransaction) {
    switch (oldVer) {
      case 0:
        upgradeUpFromV0toV1();
        break;
      default:
        console.error("Unknown DB up Ver: ", oldVer, newVer);
    }

    function upgradeUpFromV0toV1() {
      db.createObjectStore("ud", { keyPath: "key" });
      dbTransaction.objectStore("ud").add({
        key: "tk",
        val: "",
      });
    }
  },
});

import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";

const toastOption = {
  timeout: 3000,
  maxToasts: 5,
  filterBeforeCreate: (toast, toasts) => {
    if (toasts.filter(t => t.type === toast.type).length !== 0) {
      // Returning false discards the toast
      return false;
    }
    // You can modify the toast if you want
    return toast;
  },
};

createApp(App)
  .use(store)
  .use(router)
  .use(SideBar, { sidebarLinks: sidebarLinks })
  .use(PerfectScrollbar)
  .use(ElementPlus)
  .use(VueApexCharts)
  .use(Toast, toastOption)
  .mount("#app");
