import { httpClient } from "./httpClients";

const END_POINT = "user/v1";

export default function useUserAPI() {
  const postLogin = (user, pwd) =>
    httpClient.post(`${END_POINT}/login`, {
      username: user,
      password: pwd,
    });

  // TODO: use httpCLientWIthAuth - no atk
  const postLogout = (atk, rtk) =>
    httpClient.post(`${END_POINT}/logout`, {
      acc: atk,
      ref: rtk,
    });

  const postRefresh = rtk =>
    httpClient.post(`${END_POINT}/refresh`, {
      ref: rtk,
    });

  return {
    postLogin,
    postLogout,
    postRefresh,
  };
}
