import { httpClientWithAuth } from "@/resources/httpClients";

const END_POINT = "user/v1";

const getUserData = () => httpClientWithAuth.get(`${END_POINT}/data`);

const getRiskScore = () => httpClientWithAuth.get(`${END_POINT}/data/risk`);

const postRiskScore = score =>
  httpClientWithAuth.post(`${END_POINT}/data/risk`, {
    risk_score: score,
  });

export { getUserData, getRiskScore, postRiskScore };
