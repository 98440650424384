<template>
  <div class="wrapper">
    <side-bar type="sidebar" :sidebar-links="$sidebar.sidebarLinks">
      <user-menu></user-menu>
      <form
        class="navbar-form navbar-left navbar-search-form navbar-search-form-mobile"
        role="search"
      >
        <div class="input-group">
          <span class="input-group-addon"><i class="fa fa-search"></i></span>
          <input
            type="text"
            value=""
            class="form-control"
            placeholder="Search..."
          />
        </div>
      </form>
      <mobile-menu></mobile-menu>
    </side-bar>

    <div class="main-panel">
      <top-navbar></top-navbar>

      <dashboard-content @click="toggleSidebar"></dashboard-content>

      <content-footer></content-footer>
    </div>
  </div>
</template>

<script>
import TopNavbar from "@/components/pages/simDashboard/TopNavBar";
import ContentFooter from "@/components/layouts/SimFooter";
import DashboardContent from "@/components/layouts/SimContent";
import UserMenu from "@/components/shared/menus/UserMenu";
import MobileMenu from "@/components/shared/menus/MobileMenu";

export default {
  components: {
    TopNavbar,
    ContentFooter,
    DashboardContent,
    UserMenu,
    MobileMenu,
  },
  methods: {
    toggleSidebar() {
      if (this.$sidebar.showSidebar) {
        this.$sidebar.displaySidebar(false);
      }
    },
  },
};
</script>
