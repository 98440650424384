<template>
  <div>
    <div class="col-md-12">
      <buy-fund-modal></buy-fund-modal>
      <sell-fund-modal></sell-fund-modal>
      <h3><b>Top Recommended Funds</b></h3>
      <br />
      <el-table
        class="table-striped"
        :data="predictResult"
        style="width: 100%"
        max-height="650"
      >
        <el-table-column
          v-for="column in tableColumns"
          :key="column.label"
          :min-width="column.minWidth"
          :prop="column.prop"
          :label="column.label"
        >
        </el-table-column>
        <el-table-column min-width="55" fixed="left" prop="count" label="">
        </el-table-column>
        <el-table-column min-width="120" fixed="left" prop="code" label="Fund">
        </el-table-column>
        <el-table-column min-width="185" label="Recommend to purchase">
          <template #default="props">
            <el-progress :percentage="props.row.buy_prob"></el-progress>
            <!-- <p>{{ props.row.buy_prob }}</p> -->
          </template>
        </el-table-column>
        <el-table-column min-width="185" fixed="right" label="">
          <template #default="props">
            <el-button-group>
              <el-button
                size="small"
                @click="handleClickBuy(props.$index, props.row)"
                >Purchase
              </el-button>
              <el-button
                size="small"
                @click="handleClickSell(props.$index, props.row)"
                >Redeem
              </el-button>
            </el-button-group>
          </template>
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script>
import { onMounted, ref } from "vue";
import { useToast } from "vue-toastification";
import { getTopPredict } from "@/resources/predict.api";
import {
  useFundTransactionBuy,
  useFundTransactionSell,
} from "@/composables/fundTransaction";
import { useFundNavScope } from "@/composables/fundNav";
import dayjs from "dayjs";
import BuyFundModal from "@/views/modals/BuyFundModal";
import SellFundModal from "@/views/modals/SellFundModal";
export default {
  components: {
    BuyFundModal,
    SellFundModal,
  },
  data() {
    return {
      tableColumns: [
        {
          prop: "risk",
          label: "Risk",
          minWidth: 50,
        },
      ],
    };
  },
  setup() {
    let predictResult = ref([]);
    const toast = useToast();

    const { showBuyModal } = useFundTransactionBuy();
    const { showSellModal } = useFundTransactionSell();
    const { FetchNavLatestScope, SetGlobal } = useFundNavScope();

    function handleClickBuy(index, row) {
      FetchNavLatestScope(row.fund_id, "2w")
        .then(() => {
          SetGlobal();
          showBuyModal(row.fund_id, row.code, row.bcat_id);
        })
        .catch(err => {
          console.error(err);
          toast.error(`Cannot purchase ${row.code}`);
        });
    }

    function handleClickSell(index, row) {
      FetchNavLatestScope(row.fund_id, "2w")
        .then(() => {
          SetGlobal();
          showSellModal(row.fund_id, row.code, row.bcat_id);
          console.debug(`Redeem ${index}`, row);
        })
        .catch(err => {
          console.error("Failed to open redeem dialog ", err);
          toast.error(`Cannot redeem ${row.code}`);
        });
    }

    onMounted(async () => {
      try {
        const response = await getTopPredict();
        const data = response.data;
        let i = 0;
        data.map(elem => {
          elem.count = ++i;
          elem.data_date = dayjs(elem.data_date).format("DD/MM/YY");
          return elem;
        });
        predictResult.value = data;
      } catch (err) {
        console.error("Fetch order histroy failed", err);
        toast.warning("Fetch order histroy failed");
      }
    });

    return {
      handleClickBuy,
      handleClickSell,
      predictResult,
    };
  },
};
</script>

<style>
.main-panel > .content {
  padding: 300px 15px 0 15px;
  min-height: calc(100% - 123px);
}
</style>
