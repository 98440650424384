<template>
  <label>Fund to redeem</label>
  <p>{{ sellFundCode }}</p>
  <label>Order effective date</label>
  <p>{{ orderEffDate }}</p>
  <label>To Redeem</label>
  <p>{{ redeemTxt }}</p>

  <el-divider />
  <label>Balance after this transaction</label>
  <p>฿ {{ avalBal }} >>> ฿ {{ avalBalAfterSell }}</p>
  <label>Remaining units after this transaction</label>
  <p>{{ remainSellUnit }}</p>
</template>

<script>
import { computed } from "vue";
import { useFundNAV } from "@/composables/fundNav";
import useFundWallet from "@/composables/fundWallet";
import { useFundTransactionSell } from "@/composables/fundTransaction";
export default {
  setup() {
    const { currentNavDate } = useFundNAV();
    const {
      redeemOptions,
      sellFundCode,
      remainSellUnit,
      sellUnit,
      sellAmount,
    } = useFundTransactionSell();

    const { avaliableBalance } = useFundWallet();

    return {
      orderEffDate: computed(() => currentNavDate.value.format("DD/MM/YYYY")),
      redeemOptionTxt: computed(() => {
        if (redeemOptions.value == 0) return "Value (THB)";
        if (redeemOptions.value == 1) return "Unit";
        if (redeemOptions.value == 2) return "All Unit";
        else return "Unknown options";
      }),
      redeemTxt: computed(() => {
        const unitStr = sellUnit.value.todp(4);
        const amountStr = sellAmount.value.toFixed(2);
        if (redeemOptions.value == 0) return `THB ${amountStr}`;
        if (redeemOptions.value == 1) return `${unitStr} units`;
        else return `ALL (Amount: ฿ ${amountStr} | ${unitStr} units)`;
      }),
      sellFundCode,
      remainSellUnit,
      avalBalAfterSell: computed(() =>
        Number(
          avaliableBalance.value.add(sellAmount.value).toFixed(2)
        ).toLocaleString()
      ),
      avalBal: computed(() =>
        Number(avaliableBalance.value.toFixed(2)).toLocaleString()
      ),
    };
  },
};
</script>
