import { computed, reactive, ref } from "vue";
import { useRouter } from "vue-router";
import useAppState from "@/composables/appState";
import useFundPort from "@/composables/fundPort";
import useFundWallet from "@/composables/fundWallet";
import Decimal from "decimal.js-light";
import { useToast } from "vue-toastification";
import { useFundNAV } from "@/composables/fundNav";
import swAlert from "sweetalert2";

import dayjs from "dayjs";
var utc = require("dayjs/plugin/utc");
dayjs.extend(utc);

const MAX_BUY_STEP = 1;
const step = ref(0);
const isBuyModalVisible = ref(false);

// const offerNAV = ref("");
const buyAmount = ref("");
const buyOrderDate = ref(null); // UTC
const fundID = ref("");
const fundCode = ref("");
const buyBrdCatID = ref(0);
const minFirstTime = ref(500);
const minSubsequence = ref(100);
const showMinSubsequence = ref(true);

const fundInBuyOrder = reactive({
  fundID,
  fundCode,
  buyBrdCatID,
});

function useFundTransactionBuy() {
  const router = useRouter();
  const { resetEdit } = useAppState();
  const { addFundToPort, isFundInPort } = useFundPort();
  const {
    ShowAmountToBeTopUp,
    PurchaseLocal,
    CheckPurchaseEligibility,
  } = useFundWallet();
  const { currentNav, currentNavDate } = useFundNAV();
  const toast = useToast();

  function confirmFundPurchase() {
    console.debug("confirm fund purchase");

    PurchaseLocal(buyAmount.value, fundInBuyOrder.fundCode);
    addFundToPort(
      fundID.value,
      fundCode.value,
      buyBrdCatID.value,
      buyUnitDecimal.value,
      buyAmount.value,
      currentNav.value,
      currentNavDate.value.format("YYYY-MM-DD")
    )
      .then(() => {
        closeBuyModal();

        swAlert
          .fire({
            title: `Purchase successfully`,
            icon: "success",
            text: "Purchase order has been submitted.",
            showDenyButton: true,
            confirmButtonText: `View Portfolio`,
            confirmButtonColor: "#c78b0a",
            denyButtonText: "View Fund Info",
            denyButtonColor: "#2f5d62",
          })
          .then(result => {
            if (result.isConfirmed) {
              router.push({ name: "Portfolio" });
            } else if (result.isDenied) {
              router.push({
                name: "FundInfo",
                params: { fundCode: fundCode.value },
              });
            }
          });
      })
      .catch(err => {
        closeBuyModal();
        console.error("error", err);
        swAlert.fire({
          title: "Order is rejected :(",
          icon: "error",
          text: `Unable to redeem ${sellFundCode.value}. Please try again later.`,
        });
      });

    // TODO: add to pending order
    // TODO: send req to server
  }

  function closeBuyModal() {
    isBuyModalVisible.value = false;
    console.debug("close buy modal");

    if (step.value == MAX_BUY_STEP) {
      resetBuyModal();
    }
  }

  function nextStep() {
    if (step.value == 0) {
      if (buyAmount.value == "") {
        toast.warning("Purchase amount is empty");
        return;
      }
      if (new Decimal(buyAmount.value).lessThan(minBuyAmount.value)) {
        console.warn("Purchase amount is an invalid value");
        toast.warning(
          `Purchase amount is less than ฿ ${minBuyAmount.value
            .toNumber()
            .toLocaleString()}`
        );
        return;
      }
    } else if (step.value == MAX_BUY_STEP) {
      confirmFundPurchase();
    }

    if (step.value < MAX_BUY_STEP) step.value += 1;
  }

  function prevStep() {
    if (step.value > 0) step.value -= 1;
  }

  function showBuyModal(fID, fCode, bCatID) {
    showMinSubsequence.value = isFundInPort(fCode);
    isBuyModalVisible.value = true;
    fundID.value = fID;
    fundCode.value = fCode;
    fundInBuyOrder.buyBrdCatID = bCatID;
    buyOrderDate.value = dayjs(); // UTC
  }

  function resetBuyModal() {
    isBuyModalVisible.value = false;
    buyAmount.value = "";
    buyOrderDate.value = null;
    fundID.value = "";
    // fundCode.value = "";
    buyBrdCatID.value = 0;
    resetEdit();
    step.value = 0;
  }

  const amountToBeTopUp = computed(() => {
    if (buyAmount.value == "") {
      return "";
    }
    return ShowAmountToBeTopUp(buyAmount.value);
  });

  const minBuyAmount = computed(() => {
    if (showMinSubsequence.value) return new Decimal(minSubsequence.value);
    return new Decimal(minFirstTime.value);
  });

  const buyUnitDecimal = computed(() =>
    Number(buyAmount.value) < minBuyAmount.value.toNumber() ||
    currentNav.value == ""
      ? new Decimal(0)
      : new Decimal(buyAmount.value).div(currentNav.value)
  );

  const isEligibleToBuy = computed(() => {
    if (buyAmount.value == "") return false;

    return (
      CheckPurchaseEligibility(buyAmount.value) &&
      !buyUnitDecimal.value.lessThanOrEqualTo(0)
    );
  });

  return {
    nextStep,
    prevStep,
    showBuyModal,
    closeBuyModal,
    currentStep: computed(() => step.value),
    isBuyModalVisible,
    buyAmount,
    minBuyAmount,
    buyUnitDecimal,
    showMinSubsequence,
    buyOrderDate: computed(() =>
      buyOrderDate.value
        .utc()
        .local()
        .format("DD/MM/YYYY")
    ),
    amountToBeTopUp,
    fundInBuyOrder,
    confirmFundPurchase,
    isEligibleToBuy,
  };
}

// -----------------

const sellStep = ref(0);
const isSellModalVisible = ref(false);
const MAX_SELL_STEP = 1;
const sellUnitInput = ref("");
const sellAmountInput = ref("");
const sellUnit = ref(new Decimal(0));
const sellAmount = ref(new Decimal(0));
const sellOrderDate = ref();
const sellFundID = ref("");
const sellFundCode = ref("");
const sellFundBrdCatID = ref(0);
const redeemOptions = ref(0);
const fundInPort = ref();

function useFundTransactionSell() {
  const { resetEdit } = useAppState();
  const { isFundInPort, sellFund, getFundInPort } = useFundPort();
  const { currentNavDate, currentNav } = useFundNAV();
  const toast = useToast();
  const router = useRouter();

  function nextStep() {
    if (sellStep.value < 1) {
      sellStep.value++;
    } else {
      confirmFundRedeem();
    }
  }

  function confirmFundRedeem() {
    sellFund(
      sellFundID.value,
      sellFundCode.value,
      sellFundBrdCatID.value,
      sellUnit.value.toNumber(),
      sellAmount.value.toFixed(2),
      currentNav.value,
      currentNavDate.value.format("YYYY-MM-DD")
    )
      .then(() => {
        closeSellModal();
        swAlert
          .fire({
            title: `Redeem ${sellFundCode.value} successfully`,
            icon: "success",
            text: "Redeem order has been submitted.",
            showDenyButton: true,
            confirmButtonText: `View Portfolio`,
            confirmButtonColor: "#c78b0a",
            denyButtonText: "View Fund Info",
            denyButtonColor: "#2f5d62",
          })
          .then(result => {
            if (result.isConfirmed) {
              router.push({ name: "Portfolio" });
            } else if (result.isDenied) {
              router.push({
                name: "FundInfo",
                params: { fundCode: sellFundCode.value },
              });
            }
          });
      })
      .catch(err => {
        closeSellModal();
        console.error(err);
        swAlert.fire({
          title: "Order is rejected :(",
          icon: "error",
          text: `Unable to redeem ${sellFundCode.value}. Please try again later.`,
        });
      });
  }

  function prevStep() {
    if (sellStep.value > 0) sellStep.value -= 1;
  }

  function closeSellModal() {
    isSellModalVisible.value = false;
    console.debug("close sell modal");

    if (sellStep.value == MAX_SELL_STEP) resetSellModal();
  }

  function showSellModal(fID, fCode, bCatID) {
    if (isFundInPort(fCode)) {
      fundInPort.value = getFundInPort(fCode);
      sellFundID.value = fID;
      sellFundCode.value = fCode;
      sellFundBrdCatID.value = bCatID;
      isSellModalVisible.value = true;
    } else {
      console.error(`Cannot redeem: ${fCode} is not in your port.`);
      toast.error(`Cannot redeem: ${fCode} is not in your port.`);
    }
  }

  function resetSellModal() {
    console.debug("enter reset sell modal");
    sellAmountInput.value = "";
    sellUnitInput.value = "";
    sellOrderDate.value = null;
    sellFundID.value = "";
    sellFundBrdCatID.value = 0;
    fundInPort.value = {};
    resetEdit();
    sellStep.value = 0;
    console.debug("reset sell modal");
  }

  const remainSellUnit = computed(() => {
    if (fundInPort.value.unit) {
      if (sellUnit.value.lessThanOrEqualTo(fundInPort.value.unit))
        return sellUnit.value
          .sub(fundInPort.value.unit)
          .abs()
          .toFixed(4);

      return "0";
    }
    return sellUnit.value.toFixed(4);
  });

  return {
    currentStep: computed(() => sellStep.value),
    nextStep,
    prevStep,
    closeSellModal,
    isSellModalVisible,
    showSellModal,
    sellFundID,
    sellFundCode,
    MAX_SELL_STEP,
    sellAmountInput,
    sellUnitInput,
    redeemOptions,
    sellUnit,
    sellAmount,
    confirmFundRedeem,
    fundInPort,
    remainSellUnit,
  };
}

export { useFundTransactionBuy, useFundTransactionSell };
