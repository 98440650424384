<template>
  <el-dropdown @command="handleLink">
    <el-button type="text">
      <el-avatar size="small" icon="el-icon-user-solid"> </el-avatar>
      {{ username }}
      <i class="el-icon-arrow-down el-icon--right"></i>
    </el-button>
    <template #dropdown>
      <el-dropdown-menu>
        <el-dropdown-item command="P">Portfolio</el-dropdown-item>
        <el-dropdown-item command="D">Fund Discovery</el-dropdown-item>
        <el-dropdown-item command="KB" divided>Knowledge Base</el-dropdown-item>
        <el-dropdown-item command="R">Risk Quiz</el-dropdown-item>
        <el-dropdown-item command="L" divided>Logout</el-dropdown-item>
      </el-dropdown-menu>
    </template>
  </el-dropdown>
</template>

<script>
import { toRefs } from "vue";
import useUserData from "@/composables/userData";
import useAuth from "@/composables/auth";
import { useRouter } from "vue-router";
import { useToast } from "vue-toastification";
import useUserAPI from "@/resources/user.api";
export default {
  setup() {
    const { authState, clearAuth, getToken } = useAuth();
    const router = useRouter();
    const toast = useToast();
    const { username, ClearUserData } = useUserData();

    const { aToken } = toRefs(authState);

    const { postLogout } = useUserAPI();

    async function SignOut() {
      const rtk = await getToken();

      postLogout(aToken.value, rtk)
        .then(() => {
          clearAuth();
          ClearUserData();
          toast.success("Logout successfully");
          router.push({ name: "Home" });
        })
        .catch(err => {
          toast.error("Logout failed");
          console.error("Logout failed: ", err);
        });
    }

    const pushToPort = () => {
      router.push({ name: "Portfolio" });
    };

    const pushToDisc = () => {
      router.push({ name: "FundDiscovery" });
    };

    const pushToKb = () => {
      router.push({ name: "KnowledgeFund" });
    };

    const pushToRisk = () => {
      router.push({ name: "RiskSurvey" });
    };

    const handleLink = command => {
      if (command == "L") SignOut();
      else if (command == "P") pushToPort();
      else if (command == "D") pushToDisc();
      else if (command == "KB") pushToKb();
      else if (command == "R") pushToRisk();
    };

    return {
      username,
      handleLink,
    };
  },
};
</script>
