<template>
  <el-dialog
    :title="'PURCHASE ' + fundCode"
    v-model="isBuyModalVisible"
    width="65%"
    :before-close="closeBuyModal"
    destroy-on-close
    center
  >
    <span>
      <el-steps :active="currentStep" finish-status="success" align-center>
        <el-step title="Step 1"></el-step>
        <!--description="Some description" -->
        <el-step title="Step 2"></el-step>
        <!-- <el-step title="Step 3"></el-step> -->
      </el-steps>
      <el-divider />
    </span>

    <component :is="currentComponent"></component>
    <template #footer>
      <span class="dialog-footer">
        <el-button v-show="showBackBtn" @click="prevStep">Back</el-button>
        <el-button
          v-show="showNextBtn"
          :disabled="!isEligibleToBuy"
          type="primary"
          @click="nextStep"
          >{{ nextBtnTxt }}</el-button
        >
      </span>
    </template>
  </el-dialog>
</template>

<script>
import { useFundTransactionBuy } from "@/composables/fundTransaction";
import BuyStep1 from "@/components/modals/BuyFundModal/BuyStep1";
import BuyStep2 from "@/components/modals/BuyFundModal/BuyStep2";
// import BuyStep3 from "@/components/modals/BuyFundModal/BuyStep3";
import BuyResult from "@/components/modals/BuyFundModal/BuyResult";
import { computed, toRefs } from "@vue/runtime-core";
export default {
  name: "BuyFundModal",
  components: {
    BuyStep1,
    BuyStep2,
    // BuyStep3,
    BuyResult,
  },

  setup() {
    const {
      currentStep,
      nextStep,
      prevStep,
      isBuyModalVisible,
      closeBuyModal,
      confirmFundPurchase,
      fundInBuyOrder,
      isEligibleToBuy,
    } = useFundTransactionBuy();

    const TOTAL_STEPS = 2;

    const { fundCode } = toRefs(fundInBuyOrder);

    const currentComponent = computed(() => {
      return `BuyStep${currentStep.value + 1}`;
    });

    return {
      currentStep,
      nextStep,
      prevStep,
      isBuyModalVisible,
      closeBuyModal,
      confirmFundPurchase,
      currentComponent,
      fundCode,
      isEligibleToBuy,
      showBackBtn: computed(() => currentStep.value > 0),
      showNextBtn: computed(() => currentStep.value < TOTAL_STEPS),
      nextBtnTxt: computed(() =>
        currentStep.value < TOTAL_STEPS - 1 ? "Next" : "Confirm"
      ),
    };
  },
};
</script>
