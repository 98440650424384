<template>
  <nav class="navbar navbar-default">
    <div class="container-fluid">
      <div class="navbar-minimize">
        <button class="btn btn-fill btn-icon" @click="minimizeSidebar">
          <i :class="$sidebar.isMinimized ? 'ti-menu-alt' : 'ti-more-alt'"></i>
        </button>
      </div>
      <div class="navbar-header">
        <button
          type="button"
          class="navbar-toggle"
          :class="{ toggled: $sidebar.showSidebar }"
          @click="toggleSidebar"
        >
          <span class="sr-only">Toggle navigation</span>
          <span class="icon-bar bar1"></span>
          <span class="icon-bar bar2"></span>
          <span class="icon-bar bar3"></span>
        </button>
        <a class="navbar-brand">{{ this.$route.meta.navBar }}</a>
      </div>

      <div class="collapse navbar-collapse">
        <ul class="nav navbar-nav navbar-right">
          <li class="open" style="margin-right: 20em; margin-top: 17px;">
            <fund-search-box></fund-search-box>
          </li>
          <li class="open">
            <router-link
              :to="{ name: 'Home' }"
              class="btn-magnify"
              data-toggle="dropdown"
            >
              <i class="ti-panel">&nbsp; </i>
              <p>Home</p>
            </router-link>
          </li>
          <!-- <li>
            <router-link :to="{ name: 'Login' }" class="btn-magnify">
              <i class="ti-shift-right"> &nbsp; </i>
              <p>Sign Out</p>
            </router-link>
          </li> -->
          <li class="open">
            <el-button
              :disabled="!isAuth"
              class="btn-magnify btn-signout"
              icon="ti-shift-right"
              @click="signOut"
              style="  margin: 16px 0px;
                padding: 10px 15px;
                border-color:white;
                background-color:white;"
            >
              &nbsp;&nbsp;Sign Out
            </el-button>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</template>
<script>
import { toRefs } from "vue";
import useAuth from "@/composables/auth";
import useUserData from "@/composables/userData";
import useUserAPI from "@/resources/user.api";
import { useRouter } from "vue-router";
import FundSearchBox from "@/components/shared/FundSearchBox";
import { useToast } from "vue-toastification";
export default {
  components: {
    FundSearchBox,
  },
  data() {
    return {
      activeNotifications: false,
    };
  },
  methods: {
    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
    toggleNotificationDropDown() {
      this.activeNotifications = !this.activeNotifications;
    },
    closeDropDown() {
      this.activeNotifications = false;
    },
    toggleSidebar() {
      this.$sidebar.displaySidebar(!this.$sidebar.showSidebar);
    },
    hideSidebar() {
      this.$sidebar.displaySidebar(false);
    },
    minimizeSidebar() {
      this.$sidebar.toggleMinimize();
    },
  },
  setup() {
    const { clearAuth, getToken, authState, isAuth } = useAuth();
    const router = useRouter();
    const { postLogout } = useUserAPI();
    const toast = useToast();
    const { ClearUserData } = useUserData();

    const { aToken } = toRefs(authState);

    async function signOut() {
      const rtk = await getToken();

      postLogout(aToken.value, rtk)
        .then(() => {
          clearAuth();
          ClearUserData();
          toast.success("Logout successfully");
          router.push({ name: "Home" });
        })
        .catch(err => {
          toast.error("Logout failed");
          console.error("Logout failed: ", err);
        });
    }

    return {
      signOut,
      isAuth,
    };
  },
};
</script>
