<template>
  <div
    :class="sidebarClasses"
    :data-background-color="backgroundColor"
    :data-active-color="activeColor"
  >
    <perfect-scrollbar class="sidebar-wrapper" ref="sidebarScrollArea">
      <slot></slot>
      <ul :class="navClasses" style="margin: 10px 0px 0px 30px;">
        <slot name="links">
          <sidebar-item
            v-for="(link, index) in sidebarLinks"
            :key="link.name + index"
            :link="link"
          >
            <sidebar-item
              v-for="(subLink, index) in link.children"
              :key="subLink.name + index"
              :link="subLink"
            >
            </sidebar-item>
          </sidebar-item>
        </slot>
      </ul>
    </perfect-scrollbar>
  </div>
</template>
<script>
// Already imported "perfect-scrollbar" in main.js;
export default {
  props: {
    title: {
      type: String,
      default: "Mutual Fund Application",
    },
    type: {
      type: String,
      default: "sidebar",
      validator: value => {
        let acceptedValues = ["sidebar", "navbar"];
        return acceptedValues.indexOf(value) !== -1;
      },
    },
    backgroundColor: {
      type: String,
      default: "black",
      validator: value => {
        let acceptedValues = ["white", "brown", "black"];
        return acceptedValues.indexOf(value) !== -1;
      },
    },
    activeColor: {
      type: String,
      default: "success",
      validator: value => {
        let acceptedValues = [
          "primary",
          "info",
          "success",
          "warning",
          "danger",
        ];
        return acceptedValues.indexOf(value) !== -1;
      },
    },
    logo: {
      type: String,
      default: "static/img/vue-logo.png",
    },
    sidebarLinks: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    sidebarClasses() {
      if (this.type === "sidebar") {
        return "sidebar";
      } else {
        return "collapse navbar-collapse off-canvas-sidebar";
      }
    },
    navClasses() {
      if (this.type === "sidebar") {
        return "nav";
      } else {
        return "nav navbar-nav";
      }
    },
  },
  beforeUnmount() {
    if (this.$sidebar.showSidebar) {
      this.$sidebar.showSidebar = false;
    }
  },
};
</script>
<style>
@media (min-width: 992px) {
  .navbar-search-form-mobile,
  .nav-mobile-menu {
    display: none;
  }
}
</style>
