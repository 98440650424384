<template>
  <base-layout isNavDark>
    <template v-slot:content>
      <div class="wrapper wrapper-full-page">
        <div class="register-page">
          <!--   you can change the color of the filter page using: data-color="blue | azure | green | orange | red | purple" -->
          <div class="content">
            <div class="container">
              <div class="row">
                <!--------------------- select one of the three pages ------------------------------>
                <div
                  class="guide col-md-8 col-md-offset-2"
                  style="margin-top: 15%;box-shadow: 0 4px 11px 0 rgb(0 0 0 / 15%);background-color: #fff;"
                >
                  <router-link :to="{ name: 'KnowledgeFund' }">
                    <div class="col-md-4 center kn-act-11">
                      <h5 class="margin-zero" style="color:black">
                        Mutual fund
                      </h5>
                    </div>
                  </router-link>

                  <router-link :to="{ name: 'KnowledgeRisk' }">
                    <div
                      class="col-md-4 center kn-act-12"
                      style="border-left: 2px solid #ececec"
                    >
                      <h5 class="margin-zero">Investment Risk</h5>
                    </div>
                  </router-link>

                  <router-link :to="{ name: 'KnowledgeNAV' }">
                    <div
                      class="col-md-4 center kn-act-13"
                      style="border-left: 2px solid #ececec"
                    >
                      <h5 class="margin-zero">NAV</h5>
                    </div>
                  </router-link>
                </div>

                <!---------------------------------------- start first section ---------------------------------------->
                <div class="col-md-8 col-md-offset-2">
                  <div class="header-text">
                    <!-- <h2>Mutual fund</h2> -->
                    <h4 class="info-topic">
                      What is a mutual fund ? Why a mutual fund ?
                    </h4>
                    <hr
                      style="height:2px;border-width:0;color:black;background-color:black"
                    />
                    <h5 class="info-knowledge">
                      &emsp; &emsp; &emsp; &emsp; A mutual fund gathers the
                      money from individual investors into a pool of funds,
                      which will be invested in different asset such as bonds,
                      stocks to yield a return. Either profit or loss will be
                      accumulated in the mutual fund in the form of
                      <router-link
                        :to="{ name: 'KnowledgeNAV' }"
                        class="risk-act"
                        style="color:#fdbc12;"
                        ><b>Net Asset Value (NAV)</b></router-link
                      >, which will be proportionately paid back to the
                      investor.
                    </h5>
                  </div>
                  <!-- mutual fund image -->
                  <div class="img-container">
                    <img
                      src="@/assets/static/img/what-is-mutualfund.png"
                      alt="Agenda"
                      style="max-width:100%;padding-bottom:50px"
                    />
                  </div>

                  <div class="col-md-5">
                    <h5
                      class="info-knowledge"
                      style="padding-bottom:20px;text-align:left;"
                    >
                      <br /><br />
                      Each mutual fund invest in a variety of stocks, bonds or
                      other securities to meet specific investment objective
                      such as income or growth, such as income or growth.
                    </h5>
                  </div>

                  <div class="col-md-7">
                    <div class="img-container">
                      <img
                        src="@/assets/static/img/fund-pic-1.png"
                        alt="Agenda"
                        style="max-width:100%"
                      />
                    </div>
                  </div>

                  <div class="header-text col-md-12" style="padding-top:30px">
                    <h5 class="info-knowledge-div">
                      <h5
                        style="color:rgb(102, 97, 91);font-weight:1.5em;font-weight:600"
                      >
                        Mutual Funds and Diversification
                      </h5>
                      Mutual funds mitigate risk through diversifying
                      investments among a variety of asset classes (e.g. stocks,
                      bond), and a variety within a single asset class (e.g.
                      stocks from large to small companies, international and
                      domestic companies)
                    </h5>
                  </div>
                </div>
                <!---------------------------------------- end of first section ---------------------------------------->
                <div class="col-md-8 col-md-offset-2">
                  <div class="header-text">
                    <button
                      type="button"
                      class="btn btn-fill btn-danger btn-wd"
                    >
                      <router-link to="/home"
                        ><a style="color:white">Back to home</a></router-link
                      >
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
  </base-layout>
</template>

<script>
import BaseLayout from "@/components/layouts/BaseLayout";
export default {
  components: {
    BaseLayout,
  },
  data() {
    return {
      activeTab: "result",
      series: [44, 55, 41, 17, 15],
      chartOptions: {
        chart: {
          type: "donut",
        },
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 200,
              },
              legend: {
                position: "bottom",
              },
            },
          },
        ],
      },
    };
  },
};
</script>
<style>
#containerIntro h5,
#containerIntro p {
  display: inline;
}

.kn-act-11 {
  background-color: #ffb41d;
  border-radius: 8px 0px 0px 8px;
}

.kn-act-12:hover {
  background-color: #ffb41d;
  transition: all 0.4s;
}

.kn-act-13:hover {
  background-color: #ffb41d;
  transition: all 0.4s;
  border-radius: 0px 8px 8px 0px;
}
</style>
