<template>
  <label>Fund to purchase</label>
  <p>{{ fundCode }}</p>
  <label>Order Effective Date</label>
  <p>{{ orderEffDate }}</p>
  <label>Purchase value</label>
  <p>฿ {{ buyAmount }}</p>

  <el-divider></el-divider>
  <label>Wallet balance after this transaction</label>
  <p>฿ {{ avalBal }} >>> ฿ {{ avalBalAfterBuy }}</p>

  <label>Unit to be recieved</label>
  <p>{{ approxBuyUnit }}</p>
</template>

<script>
import { useFundTransactionBuy } from "@/composables/fundTransaction";
import { useFundNAV } from "@/composables/fundNav";
import useFundWallet from "@/composables/fundWallet";
import { computed, toRefs } from "vue";
import Decimal from "decimal.js-light";
export default {
  setup() {
    const {
      buyAmount,
      fundInBuyOrder,
      buyUnitDecimal,
    } = useFundTransactionBuy();

    const { currentNavDate } = useFundNAV();
    const { avaliableBalance } = useFundWallet();

    const { fundCode } = toRefs(fundInBuyOrder);

    return {
      buyAmount: computed(() => new Decimal(buyAmount.value).toFixed(2)),
      orderEffDate: computed(() => currentNavDate.value.format("DD/MM/YYYY")),
      fundCode,
      approxBuyUnit: computed(() => buyUnitDecimal.value.todp(4).toString()),
      avalBalAfterBuy: computed(() =>
        Number(
          avaliableBalance.value.sub(buyAmount.value).toFixed(2)
        ).toLocaleString()
      ),
      avalBal: computed(() =>
        Number(avaliableBalance.value.toFixed(2)).toLocaleString()
      ),
    };
  },
};
</script>
