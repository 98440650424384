<template>
  <div>
    <label>Fund</label>
    <p>{{ sellFundCode }}</p>
  </div>

  <label>NAV as of {{ currentNavDate.format("DD/MM/YYYY") }}</label>
  <p>{{ currentNav }}</p>

  <!-- <el-divider content-position="left">In your portfolio</el-divider> -->

  <label>Avaliable amount in portfolio</label>
  <p>฿ {{ fundInPort.cost }}</p>

  <label>Avaliable unit in portfolio</label>
  <p>{{ fundInPortUnit }}</p>

  <el-divider></el-divider>

  <h6>Redeem Options</h6>
  <el-radio-group v-model="redeemOptions" style="margin-bottom: 30px;">
    <el-radio-button label="0">BAHT</el-radio-button>
    <el-radio-button label="1">UNITS</el-radio-button>
    <el-radio-button label="2">ALL IN</el-radio-button>
  </el-radio-group>

  <span v-show="redeemOptions == 0">
    <el-input
      placeholder="1.00"
      v-model="sellAmountInput"
      type="number"
      @change="setEditingForm"
    >
      <template #prepend>THB</template>
    </el-input>
    <el-alert
      v-show="remainSellUnit != 0"
      :title="'Remaining units will be ' + remainSellUnit + ' units'"
      type="info"
      effect="light"
      show-icon
      :closable="false"
    ></el-alert>
    <el-alert
      v-show="remainSellUnit == 0"
      title="Your redemption order is more than avaliable amount."
      type="warning"
      effect="light"
      show-icon
      :closable="false"
    ></el-alert>
  </span>

  <span v-show="redeemOptions == 1">
    <el-input
      placeholder="1.00"
      v-model="sellUnitInput"
      type="number"
      @change="setEditingForm"
    >
      <template #append>Unit</template>
    </el-input>
    <el-alert
      v-show="sellAmount != 0"
      :title="'You will recieve THB ' + sellAmount"
      type="info"
      effect="light"
      show-icon
      :closable="false"
    ></el-alert>
  </span>

  <div v-show="redeemOptions == 2">
    <label>Amount to be recieved</label>
    <p>฿ {{ fundInPort.cost }}</p>

    <label>Units to be deducted</label>
    <p>{{ fundInPortUnit }}</p>
  </div>
</template>

<script>
import { ref, watchEffect, computed } from "vue";
import { useFundNAV } from "@/composables/fundNav";
import { useFundTransactionSell } from "@/composables/fundTransaction";
import useAppState from "@/composables/appState";
import Decimal from "decimal.js-light";
export default {
  setup() {
    const { currentNavDate, currentNav } = useFundNAV();
    const {
      sellFundCode,
      redeemOptions,
      sellAmount,
      sellUnit,
      fundInPort,
      remainSellUnit,
    } = useFundTransactionSell();
    const { setEditingForm } = useAppState();

    const sellAmountInput = ref("");
    const sellUnitInput = ref("");

    watchEffect(() => {
      if (redeemOptions.value == 0) {
        let amount =
          sellAmountInput.value == "" || Number(sellAmountInput.value) < 1
            ? new Decimal(0)
            : new Decimal(sellAmountInput.value);
        sellAmount.value = amount;
        sellUnit.value = amount.div(currentNav.value);
      } else if (redeemOptions.value == 1) {
        let unit =
          sellUnitInput.value == "" || Number(sellUnitInput.value) < 1
            ? new Decimal(0)
            : new Decimal(sellUnitInput.value);
        sellAmount.value = unit.mul(currentNav.value);
        sellUnit.value = unit;
      } else {
        if (fundInPort.value.cost != undefined) {
          sellAmount.value = new Decimal(fundInPort.value.cost);
          sellUnit.value = new Decimal(fundInPort.value.unit);
        }
      }
    });

    return {
      currentNav,
      currentNavDate,
      sellFundCode,
      redeemOptions,
      sellAmountInput,
      sellUnitInput,
      setEditingForm,
      fundInPort,
      sellAmount: computed(() => sellAmount.value.toFixed(2)),
      remainSellUnit,
      fundInPortUnit: computed(() =>
        new Decimal(fundInPort.value.unit).toFixed(4)
      ),
    };
  },
};
</script>
