<template>
  <el-card
    style="margin-bottom:30px;padding-bottom:20px"
    v-show="Number(fund.unit) != 0"
  >
    <template #header>
      <div class="col-md-12 clearfix">
        <router-link
          :to="{ name: 'FundInfo', params: { fundCode: fund.code } }"
        >
          <h4 class="col-md-6">{{ fund.code }}</h4></router-link
        >
        <div class="topup-btn">
          <el-button size="small" @click="OpenBuyModal">Purchase</el-button>
          <el-button size="small" @click="OpenSellModal">Redeem</el-button>
        </div>
      </div>
    </template>

    <br />
    <el-divider />
    <div style="padding-left:30px">
      <p class="topic-fund">Current value</p>
      <p class="text-fund">{{ upDownIndicator }}{{ currentVal }}</p>

      <p class="topic-fund">P/L</p>
      <p class="text-fund">{{ gainLoss }} ({{ gainLossPercent }} %)</p>

      <p class="topic-fund">Unit</p>
      <p class="text-fund">{{ Number(fund.unit).toFixed(4) }}</p>
    </div>
    <el-divider />
    <div class="col-md-12">
      <div class="col-md-6">
        <p class="topic-fund">Avg. cost unit</p>
        <p class="text-fund">{{ avgCostUnit }}</p>

        <p class="topic-fund">Current NAV</p>
        <p class="text-fund">{{ currentNav }}</p>
      </div>

      <div class="col-md-6" style="text-align:right">
        <p class="topic-fund">Avg. cost value</p>
        <p class="text-fund">{{ avgCostValue }}</p>

        <p class="topic-fund">Current value</p>
        <p class="text-fund">{{ currentVal }}</p>
      </div>
    </div>
  </el-card>
</template>

<script>
import Decimal from "decimal.js-light";
import { useFundNavScope } from "@/composables/fundNav";
import {
  useFundTransactionBuy,
  useFundTransactionSell,
} from "@/composables/fundTransaction";
import { computed, onMounted } from "vue";
export default {
  props: {
    fund: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const {
      FetchNavLatestScope,
      latestNavDataScope,
      SetGlobal,
    } = useFundNavScope();
    const { showBuyModal } = useFundTransactionBuy();
    const { showSellModal } = useFundTransactionSell();

    const currentVal = computed(() => {
      if (latestNavDataScope.value != null)
        return new Decimal(latestNavDataScope.value.current_nav)
          .mul(props.fund.unit)
          .toFixed(2);
      return "0";
    });

    const currentNav = computed(() => {
      if (latestNavDataScope.value == null) return "-";
      return new Decimal(latestNavDataScope.value.current_nav).toFixed(4);
    });

    const gainLoss = computed(() => {
      return new Decimal(currentVal.value).minus(props.fund.cost).toFixed(2);
    });

    const gainLossPercent = computed(() => {
      if (props.fund.cost != 0)
        return new Decimal(gainLoss.value)
          .div(props.fund.cost)
          .mul(100)
          .toFixed(2);
      return "-";
    });

    const avgCostValue = computed(() => {
      return new Decimal(currentVal.value).minus(gainLoss.value).toFixed(2);
    });

    const avgCostUnit = computed(() => {
      return new Decimal(avgCostValue.value).div(props.fund.unit).toFixed(4);
    });

    onMounted(() => FetchNavLatestScope(props.fund.fund_id, "2mo")); // TODO: fetch latest

    function OpenBuyModal() {
      SetGlobal();
      showBuyModal(props.fund.fund_id, props.fund.code, props.fund.bcat_id);
    }

    function OpenSellModal() {
      SetGlobal();
      showSellModal(props.fund.fund_id, props.fund.code, props.fund.bcat_id);
    }

    return {
      latestNavDataScope,
      currentNav,
      currentVal,
      gainLoss,
      gainLossPercent,
      avgCostValue,
      avgCostUnit,
      OpenBuyModal,
      OpenSellModal,
      upDownIndicator: computed(() => {
        if (latestNavDataScope.value == null) return null;
        if (latestNavDataScope.value.is_change_up) return "▲ ";
        return "▼ ";
      }),
    };
  },
};
</script>
